import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';

/**
 * Component representing a tree structure with expandable nodes and checkable items.
 */
@Component({
  selector: 'tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnChanges {
  /**
   * Input property for the tree data in JSON format.
   * @type {any[]}
   */
  @Input() treeJSON: any[] = [];

  /**
   * Input property for the current level of the tree node.
   * @type {number}
   */
  @Input() level: number = 0;

  /**
   * Output event emitter for emitting the checked status of a node.
   * @type {EventEmitter<any>}
   */
  @Output() nodeChecked = new EventEmitter<any>();

  /**
   * Set to keep track of expanded node IDs.
   * @type {Set<number>}
   */
  expandedNodes: Set<number> = new Set();

  /**
   * Angular lifecycle hook that responds to changes in input properties.
   * @param {SimpleChanges} changes - The changes object containing the previous and current values of input properties.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['treeJSON'] && changes['treeJSON'].currentValue) {
      this.treeJSON = changes['treeJSON'].currentValue;
    }
  }

  /**
   * Toggles the expanded state of a node.
   * @param {number} id - The ID of the node to toggle.
   */
  toggleNode(id: number): void {
    if (this.expandedNodes.has(id)) {
      this.expandedNodes.delete(id);
    } else {
      this.expandedNodes.add(id);
    }
  }

  /**
   * Handles the checkbox change event and emits the node's checked status.
   * @param {Event} event - The change event from the checkbox.
   * @param {any} node - The node object associated with the checkbox.
   */
  onNodeChecked(event: Event, node: any): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    node.checked = isChecked;
    this.nodeChecked.emit({ node, isChecked });
  }
}
