import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'apium-auth-layout',
  templateUrl: './apium-auth-layout.component.html',
  styleUrls: ['./apium-auth-layout.component.scss'],
})
export class ApiumAuthLayoutComponent implements OnInit {
  public routePath: string;
  private subscriptions: Subscription[] = [];
  public currentYear: number = new Date().getFullYear();

  constructor(private _route: Router) {
    this.onRouteChange();
  }

  ngOnInit(): void {}

  /**
   * *On route change event
   *
   * @date 21 July 2022
   * @developer Apium
   */
  private onRouteChange() {
    this.subscriptions.push(
      this._route.events.subscribe({
        next: (event: Event) => {
          if (event instanceof NavigationEnd) {
            this.routePath = event.url.substring(1);
          }
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
