import { Injectable } from '@angular/core';
import { HttpService } from '@core/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { decryption, encryption } from '@shared/utilities';
import { appSettings, routes } from '@app/configs';
import apiEndPoints from '@apiEndPoints';
import {
  IAuthResult,
  ISignInPayload,
} from '@app/shared/interfaces/auth.interface';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { LoaderService } from '@app/shared/modules/loader';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userCredentials: string = '';

  constructor(
    private _http: HttpService,
    private _cookieService: CookieService,
    public afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private router: Router,
    private _loader: LoaderService
  ) {}

  // Cookie Management ==================================================================
  /**
   * Check if franchise data exists in the cookie
   * @param key - The key to check in cookies
   * @returns boolean
   */
  public checkFranchiseDataExists(key: string): boolean {
    return this._cookieService.check(key);
  }

  /**
   * Store a cookie with fixed options
   * @param name - Cookie name
   * @param value - Cookie value
   */
  storeCookie(name: string, value: string): void {
    const expiresDays = 7;
    const path = '/';
    const domain = '';
    const secure = true;
    const sameSite = 'Lax';

    this._cookieService.set(
      name,
      value,
      expiresDays,
      path,
      domain,
      secure,
      sameSite
    );
  }

  /**
   * Retrieve a cookie by name
   * @param name - Cookie name
   * @returns string | null
   */
  retrieveCookie(name: string): string | null {
    return this._cookieService.get(name);
  }

  /**
   * Delete a cookie by name
   * @param name - Cookie name
   */
  deleteCookie(name: string): void {
    const path = '/';
    const domain = '';
    this._cookieService.delete(name, path, domain);
  }

  // Firebase Authentication ============================================================
  /**
   * Sign in using Firebase custom token
   * @param token - Firebase token
   * @returns Promise<any>
   */
  public signInWithFirebaseToken(token: string): Promise<any> {
    return this.afAuth.auth
      .signInWithCustomToken(token)
      .then((response: any) => response)
      .catch((error) => {
        console.log('Error: ', error);
        throw error;
      });
  }

  /**
   * Sign out from Firebase
   * @returns Promise<void>
   */
  public signOutFromFirebase(): Promise<void> {
    return this.afAuth.auth
      .signOut()
      .then(() => console.log('User signed out successfully from Firebase!'))
      .catch((error) => {
        console.error('Sign out error: ', error);
        throw error;
      });
  }

  // Authentication / HTTP Requests =====================================================
  /**
   * Forgot password functionality
   * @param params - Request parameters
   * @returns Observable<any>
   */
  public forgotPassword(params: any): Observable<any> {
    return this._http.post(apiEndPoints.forgotpassword, params).pipe(
      map((response: Response) => response),
      catchError((error) => {
        console.log('Error: ', error);
        throw error;
      })
    );
  }

  /**
   * Reset password functionality
   * @param params - Request parameters
   * @returns Observable<any>
   */
  public resetPassword(params: any): Observable<any> {
    return this._http.post(apiEndPoints.resetpassword, params).pipe(
      map((response: Response) => response),
      catchError((error) => {
        console.log('Error: ', error);
        throw error;
      })
    );
  }

  /**
   * Get all franchise-related zones
   * @returns Observable<any>
   */
  public getAllFranchiseRelatedZone(): Observable<any> {
    return this._http.get(apiEndPoints.getallfranchisepermisssion).pipe(
      map((response: Response) => response),
      catchError((error) => {
        console.log('Error: ', error);
        throw error;
      })
    );
  }

  /**
   * Get all old franchise-related zones
   * @returns Observable<any>
   */
  public getOldAllFranchiseRelatedZone(): Observable<any> {
    return this._http.get(apiEndPoints.getalloldFranchisePermisssion).pipe(
      map((response: Response) => response),
      catchError((error) => {
        console.log('Error: ', error);
        throw error;
      })
    );
  }

  /**
   * Get all market offices
   * @returns Observable<any>
   */
  public getMarketOffice(): Observable<any> {
    return this._http.get(apiEndPoints.getMarketOffice).pipe(
      map((response: Response) => response),
      catchError((error) => {
        console.log('Error: ', error);
        throw error;
      })
    );
  }

  // Real-Time Database Listener ========================================================
  /**
   * Listen for real-time updates in the zone data
   * @returns Observable<any>
   */
  public zoneListener(): Observable<any> {
    return this.db.object('delivery_zone').valueChanges();
  }

  // Logout Functionality ===============================================================
  /**
   * Log out the user
   * @param event - Optional event
   */
  public async logout(event?: Event): Promise<void> {
    if (event) {
      event.preventDefault();
    }
    try {
      // Clear cookies
      this.deleteCookie(appSettings.userDetails);
      this.deleteCookie(appSettings.token);
      this.deleteCookie(appSettings.firebaseToken);

      // Sign out from Firebase
      await this.signOutFromFirebase();

      // Navigate to the login page
      this.router.navigateByUrl(routes.LOGIN);
    } catch (error) {
      console.error('Logout error: ', error.message);
    }
  }

  /**
   * Check if the user is logged in by verifying the presence of a token
   * @returns boolean
   */
  public isLoggedIn(): boolean {
    return !!this.retrieveCookie(appSettings.token);
  }
}
