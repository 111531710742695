import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from './ensureModuleLoadedOnceGuard';

import { ApiumAuthLayoutComponent } from './layouts/auth';
import { ApiumManagerLayoutComponent } from './layouts/manager/apium-manager-layout/apium-manager-layout.component';
import { ApiumManagerSidebarLayoutComponent } from './layouts/manager/apium-sidebar-layout/apium-sidebar-layout.component';
import { ApiumManagerFooterLayoutComponent } from './layouts/manager/apium-footer-layout/apium-footer-layout.component';
import { ApiumManagerHeaderLayoutComponent } from './layouts/manager/apium-header-layout/apium-header-layout.component';
import { SharedModule } from '@app/shared/shared.module';

const MODULES = [CommonModule, RouterModule,SharedModule];

const COMPONENTS = [
  ApiumAuthLayoutComponent,
  ApiumManagerLayoutComponent,
  ApiumManagerSidebarLayoutComponent,
  ApiumManagerFooterLayoutComponent,
  ApiumManagerHeaderLayoutComponent,
];

@NgModule({
  imports: [...MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers:[...COMPONENTS]
})
export class TemplateModule {
  /**
   * !Ensuring that TemplateModule is only loaded into AppModule
   * *Looks for the module in the parent injector to see if it's already been loaded (only load once)
   *
   * @param parentModule as parameter
   * @date 28 June 2022
   * @developer Apium
   */
  constructor(@Optional() @SkipSelf() parentModule: TemplateModule) {
    throwIfAlreadyLoaded(parentModule, 'ThemeModule');
  }
}
