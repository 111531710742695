import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { appSettings, routes } from '@app/configs';
import apiEndPointsConfig from '@app/configs/api-end-points.config';
import { Errors } from '@app/configs/errors.configs';
import { AuthenticationService } from '@app/core/authentication';
import { HttpService } from '@app/core/http';
import { LoaderService } from '@app/shared/modules/loader';
import { SnackBarService } from '@app/shared/services/snack-bar.service';
import { TResponse } from '@app/shared/types/response.types';
import { LoginResponse } from '../../interfaces/auth.interface';
import { decryption, encryption } from '@app/shared/utilities';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  /** Holds the form group for the login form */
  loginForm: UntypedFormGroup;

  /** Tracks whether the form has been submitted */
  public submitted: boolean = false;

  /** Provides access to application routes for navigation */
  public routes: typeof routes = routes;

  /** Contains error messages for form validation */
  public errors: typeof Errors = Errors;

  /** Indicates whether a loading process is in progress */
  public isLoading: boolean = false;

  /** Controls the visibility of the password in the form */
  public passwordVisible: boolean = false;

  /** Holds the subscriptions for cleanup */
  private subscriptions: Subscription[] = [];

  /**
   * Constructs the LoginComponent instance.
   * @param {UntypedFormBuilder} formBuilder - The form builder service
   * @param {AuthenticationService} _authService - The authentication service
   * @param {Router} router - The Angular router service
   * @param {LoaderService} _loader - The loader service
   * @param {SnackBarService} _snack - The snack bar service
   * @param {HttpService} httpService - The HTTP service
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private _authService: AuthenticationService,
    private router: Router,
    private _loader: LoaderService,
    private _snack: SnackBarService,
    private httpService: HttpService
  ) {}

  /**
   * Initializes the component, setting up the form and loading any saved credentials.
   * @returns {void}
   */
  ngOnInit(): void {
    this._loader.hideLoader();
    this.initForm();

    // Check if credentials are stored in localStorage
    const savedEmail = localStorage.getItem('rememberMeEmail');
    const savedPasswordEncrypted = localStorage.getItem('rememberMePassword');

    let savedPassword = null;

    // Decrypt the password only if it exists
    if (savedPasswordEncrypted) {
      savedPassword = decryption(savedPasswordEncrypted);
    }

    // If both email and password exist, set them in the form
    if (savedEmail && savedPassword) {
      this.loginForm.patchValue({
        email: savedEmail,
        password: JSON.stringify(savedPassword),
        remember: true,
      });
    }
  }

  /**
   * Initializes the login form with validators.
   * @returns {void}
   */
  private initForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      remember: [false],
    });
  }

  /**
   * Toggles the visibility of the password in the form.
   * @returns {void}
   */
  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  /**
   * Provides access to the form controls for validation.
   * @returns {Object} The form controls
   */
  get formControl() {
    return this.loginForm.controls;
  }

  /**
   * Handles the login process when the form is submitted.
   * @returns {void}
   */
  onLogin(): void {
    this._loader.showLoader();
    if (this.loginForm.valid) {
      const loginData = { ...this.loginForm.value };
      const rememberMe = loginData.remember;
      delete loginData.remember;

      const loginSub = this.httpService
        .post(apiEndPointsConfig.login, loginData, {
          useUrlPrefix: true,
        })
        .subscribe({
          next: (response: TResponse) => {
            this._loader.hideLoader();

            // Extract the nested dataset as LoginResponse['dataset']
            const loginResponseDataset = response.dataset as LoginResponse;

            // Display success message using PrimeNG Toast
            this._snack.notifySuccess(response.message);

            // Save credentials if rememberMe is checked
            if (rememberMe) {
              localStorage.setItem('rememberMeEmail', loginData.email);
              localStorage.setItem(
                'rememberMePassword',
                encryption(loginData.password)
              );
            } else {
              localStorage.removeItem('rememberMeEmail');
              localStorage.removeItem('rememberMePassword');
            }

            this.handleLoginResponse(loginResponseDataset);
          },
          error: (error) => {
            this._loader.hideLoader();
            this._snack.notifyError('Login failed:', error);
            this._snack.notifyError(error.message);
          },
        });

      // Add the subscription to the array
      this.subscriptions.push(loginSub);
    } else {
      this._loader.hideLoader();
    }
  }

  /**
   * Handles the login response and processes the data.
   * @param {LoginResponse['dataset']} loginData - The data received from the login response
   * @returns {Promise<void>}
   */
  public async handleLoginResponse(loginData: LoginResponse): Promise<void> {
    try {
      const responseData = await this._authService.signInWithFirebaseToken(
        loginData.firebase_token
      );

      if (responseData.error) {
        this._loader.hideLoader();
        this._snack.notifyError('Firebase sign-in error:', responseData.error);
        return;
      }

      this._authService.storeCookie(
        appSettings.userDetails,
        encryption(JSON.stringify(loginData.user_details))
      );
      this._authService.storeCookie(appSettings.token, loginData.access_token);
      this._authService.storeCookie(
        appSettings.firebaseToken,
        loginData.firebase_token
      );

      if (loginData.user_details.force_pw_reset === 'true') {
        this._loader.hideLoader();
        this._snack.notifySuccess('Please reset your password!');
        this.router.navigateByUrl(routes.RESET_PASSWORD);
        return;
      }

      this.router.navigate([routes.DASHBOARD]);
    } catch (error) {
      this._loader.hideLoader();
      this._snack.notifyError('Login procedures error:', error);
    }
  }

  /**
   * Unsubscribes from all subscriptions when the component is destroyed.
   * @returns {void}
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
