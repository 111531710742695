import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { routes } from '@app/configs';
import apiEndPointsConfig from '@app/configs/api-end-points.config';
import { Errors } from '@app/configs/errors.configs';
import { regex } from '@app/configs/regex.config';
import { HttpService } from '@app/core/http';
import { LoaderService } from '@app/shared/modules/loader';
import { SnackBarService } from '@app/shared/services/snack-bar.service';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-pwd.component.html',
  styleUrls: ['./forgot-pwd.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  /**
   * Form group for the forgot password form.
   * @type {FormGroup}
   */
  forgotPasswordForm: FormGroup;

  /**
   * Flag to track if the form has been submitted.
   * @type {boolean}
   */
  submitted: boolean = false;

  /**
   * Error messages definition.
   * @type {typeof Errors}
   */
  errors: typeof Errors = Errors;

  /**
   * Array to hold subscriptions.
   * @type {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * Constructor that injects necessary services.
   * @param {LoaderService} loaderService - Service to control the loader.
   * @param {SnackBarService} snackBarService - Service to show snackbar messages.
   * @param {HttpService} http - HTTP service for API requests.
   * @param {FormBuilder} formBuilder - Service to build the form.
   * @param {Router} router - Service to navigate between routes.
   */
  constructor(
    private loaderService: LoaderService,
    private snackBarService: SnackBarService,
    private http: HttpService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  /**
   * Lifecycle hook that is called after Angular has initialized all data-bound properties.
   * Initializes the forgot password form with validators.
   */
  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      emailAddress: [
        '',
        [Validators.required, Validators.pattern(regex.EMAIL)],
      ],
    });
  }

  /**
   * Method triggered on form submission to initiate the password reset process.
   * Shows a loader, performs the API request, and handles the response.
   */
  forgotPassword(): void {
    this.submitted = true;

    if (this.forgotPasswordForm.valid) {
      this.loaderService.showLoader();
      const email = this.forgotPasswordForm.value.emailAddress;

      const sub = this.http
        .post(
          apiEndPointsConfig.forgotpassword,
          { email: email },
          { useUrlPrefix: true }
        )
        .subscribe({
          next: (response) => {
            this.snackBarService.notifySuccess(response['message']);
            this.router.navigate([routes.HOME]);
            this.forgotPasswordForm.reset();
          },
          error: (err) => {
            this.snackBarService.notifyError(err.message);
          },
          complete: () => {
            this.loaderService.hideLoader();
            this.submitted = false;
          },
        });

      // Push the subscription to the array
      this.subscriptions.push(sub);
    }
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Unsubscribes from all subscriptions to prevent memory leaks.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Getter method to access form controls in the template.
   * @returns {FormGroup['controls']} The form controls.
   */
  get formControls() {
    return this.forgotPasswordForm.controls;
  }
}
