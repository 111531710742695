<!-- <ng-container *ngIf="isVisible">
  <div class="overlay">
    <div class="overlay__inner">
      <div class="overlay__content">
        <div class="loader">
          <div class="page-spinner-bar">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container> -->

<ng-container *ngIf="isVisible">
  <div class="d-flex justify-content-center">
    <div
      class="spinner-border text-warning"
      style="z-index: 9999; top: 30px; position: fixed"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-container>
