import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication';
import { DriverService } from '@app/pages/driver/services/driver.service';
import { CaliforniaReportService } from '@app/pages/reports/services/california-report.service';
import {
  Driver,
  FormattedData,
  IDrivers,
  IOrganization,
  IState,
  IZone,
  MarketOffice,
} from '@app/shared/interfaces/common.interface';
import { LoaderService } from '@app/shared/modules/loader';
import {
  MarketOfficeZoneIds,
  MkOfficeService,
} from '@app/shared/services/mk-office.service';
import { SnackBarService } from '@app/shared/services/snack-bar.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { dateFormat } from '@app/shared/utilities';
import { CaliforniaReportsComponent } from '../california-reports/california-reports.component';
import { environment } from '@env/environment';
import { TooltipPosition } from '@angular/material/tooltip';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import apiEndPointsConfig from '@app/configs/api-end-points.config';
import { HttpService } from '@app/core/http/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DriverDetails } from '@app/shared/interfaces/dashboard.interface';

@Component({
  selector: 'legacy-report-filters',
  templateUrl: './legacy-report-filters.component.html',
  styleUrls: ['./legacy-report-filters.component.scss'],
})
export class LegacyReportFiltersComponent implements OnInit {
  @ViewChild(CaliforniaReportsComponent, { static: true })
  caChildComp: CaliforniaReportsComponent;
  @Input() properties: any;

  caFilterForm: UntypedFormGroup;
  // for states ,market offices ,drivers
  private COMMON_API_URL: string = environment.BASE_URL + 'common/';
  // for report ,updates
  private API_URL: string = `${environment.BASE_URL}drivers`;
  private DR_API_URL: string = `${this.API_URL}`;
  public marketOfficeList: MarketOffice[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);
  public mkOfcSpnr: boolean = true;
  public drvLstSpnr: boolean = false;
  minDateSt: Date = new Date();
  maxDateSt: Date = new Date();
  minDateEn: Date = new Date();
  maxDateEn: Date = new Date();
  public name: string;
  objectKeys = Object.keys;
  objectValues = Object.values;
  objectAssign = Object.assign;
  public report: any = null;

  public reviewCount: boolean = false;
  subscription: Subscription;
  public allDriverIds: number[] = [];
  dropdownSettingsForDrivers: IDropdownSettings = {};
  dropdownSettingsForMKO: IDropdownSettings = {};
  public selectedDriversIDs: number[] = [];
  public selectedMOIDs: string[] = []; // Utility functions for object iteration

  public MODrpdwn: MarketOfficeZoneIds[] = []; // Dropdown options for Market Office
  public driverList: DriverDetails[] = []; // Dropdown options for Drivers
  public zoneIds: string[] = []; // Selected zone IDs as strings
  public zoneIdsInt: number[] = []; // Selected zone IDs as numbers
  public allZoneIds: string[] = []; // All available zone IDs
  public driverIds: number[] = []; // Selected driver IDs
  public st_dt: string; // Start Date
  public en_dt: string; // End Date
  public selectedZones: number[] = []; // Selected zone IDs
  public marketOfficeIds: number[] = []; // Selected Market Office IDs
  public loadMkDrp: boolean = false; // Flag to check if Market Office dropdown is loaded
  public zoneIdField: string = 'zone_ids'; // Field for zone IDs in dropdown
  public zoneTextField: string = 'office_name'; // Field for zone names in dropdown
  public driverIdField: string = 'id'; // Field for driver IDs in dropdown
  public driverTextField: string = 'full_name'; // Field for driver names in dropdown
  public mkOfficePlaceholder: string = 'Select market office'; // Placeholder for Market Office dropdown
  public driverPlaceholder: string = 'Select driver'; // Placeholder for Driver dropdown
  public isLoading: boolean = false; // Flag to indicate loading state

  // Dropdown Settings
  dropdownSettingsForZone: IDropdownSettings = {};

  // Overview Data
  public final_overview_data: any = {
    drivers: 0,
    deliveries: 0,
    mileage_reimbursement: {
      miles: 0,
      pay: 0,
    },
    engaged_time_reimbursement: {
      time: 0,
      pay: 0,
    },
    tips: 0,
    incentive: 0,
    hourly_incentive: 0,
    total: 0,
  };
  public tot_driver_pay: number = 0; // Total driver pay
  public caDriverList: any; // List of drivers for a specific purpose
  public formattedData: FormattedData[] = []; // Formatted data for a specific purpose
  filterargs = { review_section: true }; // Filter arguments for data filtering

  mkOfficeForm: UntypedFormGroup; // Form group for Market Office dropdown
  driverForm: UntypedFormGroup; // Form group for Driver dropdown

  public ids: number[] = [];
  zoneForm = new FormControl([]);
  private reportVersion: string = '';

  constructor(
    private _http: HttpService,
    private _snackbar: MatSnackBar,
    private _loader: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private _auth: AuthenticationService,
    private _snack: SnackBarService,
    private _mkOffice: MkOfficeService,
    private _driverService: DriverService,
    private _CAService: CaliforniaReportService
  ) {
    this.caFilterForm = formBuilder.group({
      market_office: ['', Validators.required],
      drivers: ['', Validators.required],
      st_dt: ['', Validators.required],
      en_dt: ['', Validators.required],
    });
  }
  ngOnInit(): void {
    this._loader.showLoader();

    // get market office all details
    // this.permissionAPI();
    this.getMarketOffice();

    this.mkOfficeForm = this.formBuilder.group({
      multiselect: ['', Validators.required],
    });

    this.driverForm = this.formBuilder.group({
      multiselect: ['', Validators.required],
    });
    this.caFilterForm = this.formBuilder.group({
      st_dt: ['', Validators.required],
      en_dt: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['properties'] && changes['properties'].currentValue) {
      const { report, name, minDateSt, maxDateSt, minDateEn, maxDateEn } =
        changes['properties'].currentValue;

      this.DR_API_URL = `reports/CA/${report}`;
      this.reportVersion = `${report}`;
      this.name = name;
      this.minDateSt = minDateSt;
      this.maxDateSt = maxDateSt;
      this.minDateEn = minDateEn;
      this.maxDateEn = maxDateEn;
    }
  }

  /*
   * Select Zones
   */
  public selectZones = (event): void => {
    this.driverForm.reset();
    this.driverList = [];
    event.zone_ids.map((m) => {
      this.zoneIds.push(JSON.stringify(m));
      this.zoneIdsInt.push(m);
    });
    this.MODrpdwn.map((m) => {
      if (m.market_office_name === event.market_office_name)
        this.marketOfficeIds.push(parseInt(m.market_office_id));
    });

    this.getDriverList(this.zoneIds);
  };

  /*
   * Deselect Zone
   */
  public deselectZone = (event): void => {
    this.driverForm.reset();
    this.driverList = [];
    let kc = 0;
    event.zone_ids.map((m) => {
      if (this.zoneIds.includes(JSON.stringify(m)))
        this.zoneIds.splice(this.zoneIds.indexOf(JSON.stringify(m)), 1);
    });
    this.MODrpdwn.map((m) => {
      if (m.market_office_name === event.market_office_name) {
        this.marketOfficeIds.splice(
          this.marketOfficeIds.indexOf(parseInt(m.market_office_id)),
          1
        );
      }
    });

    if (this.marketOfficeIds.length !== 0) this.getDriverList(this.zoneIds);
  };
  /*
   * Select all Zones
   */
  public onSelectAllZone = (event): void => {
    this.driverForm.reset();
    this.driverList = [];
    this.marketOfficeIds = [];
    this.zoneIds = [];
    this.zoneIdsInt = [];

    // Extract all zone_ids
    this.zoneIds = event.reduce((acc, curr) => {
      return acc.concat(curr.zone_ids);
    }, []);
    // this.zoneIdsInt = [...this.zoneIds]; // Store a copy of zoneIds as integers

    this.marketOfficeIds = this.MODrpdwn.map((mod) => mod.market_office_id)
      .flat()
      .map(Number);
    this.getDriverList(this.zoneIds.map(String));
  };

  public onDeSelectAllZone = (event): void => {
    this.driverForm.reset();

    this.driverList = [];
    this.marketOfficeIds = [];
    this.zoneIds = [];
  };

  // ==========================================================
  // MARKET OFFICE DRIVER DROPDOWN FUNCTIONALITIES
  // ==========================================================
  /*
   * Select Drivers
   */
  public selectDrivers = (event): void => {
    this.driverIds.push(parseInt(event.id));
  };

  /*
   * Select All Drivers
   */
  public onSelectAllDrivers = (event): void => {
    this.driverIds = [];

    event.map((m) => {
      this.driverIds.push(m.id);
    });
  };

  /*
   * Deselect Driver
   */
  public deselectDriver = (event): void => {
    if (this.driverIds.includes(event.id))
      this.driverIds.splice(this.driverIds.indexOf(event.id), 1);
  };

  /*
   * Deselect all drivers
   */
  public onDeSelectAllDrivers = (event): void => {
    this.driverIds = [];
  };

  /*
   * Get Drivers
   */
  public getDriverList = (ids): void => {
    this._loader.showLoader();
    const data = {
      params: {
        limit: 100,
        page: 1,
        search_text: '',
        state_zone: {
          CA: ids,
        },
      },
    };
    this._driverService.oldDriverListAPI(data).subscribe({
      next: (response: Response) => {
        if (response['error']) {
          this._snack.notifyError(response['error'].message, 'X');
        } else {
          this.driverList = response?.['result']?.drivers || [];
          this.driverList.sort(this.compare);
          if (response['error'])
            this._snack.notifyError(response['error'].message, 'X');
        }
      },
      error: (err: Error) => {
        this._snack.notifyError(err.message, 'X');
        this._loader.hideLoader();
      },
      complete: () => {
        this._loader.hideLoader();
      },
    });
  };

  /**
   * Compare function for sorting driver names in a case-insensitive manner.
   * @param a - The first driver object.
   * @param b - The second driver object.
   * @returns -1 if a comes before b, 1 if a comes after b, and 0 if they are equal.
   */
  public compare(a, b) {
    return a.full_name.localeCompare(b.full_name, undefined, {
      sensitivity: 'base',
    });
  }

  /**
   * Compare function for sorting market offices based on their names in a case-insensitive manner.
   * @param a - The first market office object.
   * @param b - The second market office object.
   * @returns -1 if a comes before b, 1 if a comes after b, and 0 if they are equal.
   */
  public compareMO(a, b) {
    return a.market_office_name.localeCompare(b.market_office_name, undefined, {
      sensitivity: 'base',
    });
  }

  /*
   * Method:permissionAPI
   * Reason: permission API
   * Date:5th August, 2022
   */
  // public permissionAPI = async (): Promise<any> => {
  //   let mkOfficeDetails = [];
  //   let ids = [];
  //   await this._auth.getOldAllFranchiseRelatedZone().subscribe({
  //     next: (response) => {
  //       this.formattedData = this._mkOffice.getFormattedResponse(response);

  //       mkOfficeDetails = this.formattedData.filter(
  //         (m) => m.state_code === 'CA'
  //       )[0]['allMarketOffice'];

  //       mkOfficeDetails.map((m) => {
  //         ids = [];
  //         this.MODrpdwn.push({
  //           market_office_name: m.market_office_name,
  //           market_office_id: m.market_office_id.substring(2, 4).trim(),
  //         });

  //         for (const key in m.zones) {
  //           ids.push(m.zones[key].zone_id);
  //           this.ids.push(m.zones[key].zone_id);
  //         }
  //         this.MODrpdwn[mkOfficeDetails.indexOf(m)].zone_ids = ids;
  //         this.MODrpdwn.sort(this.compareMO);
  //       });
  //     },
  //     error: (error: Error) => {
  //       this._snack.notifyError(error.message, 'X');
  //       this._loader.hideLoader();
  //     },
  //     complete: () => {
  //       this._loader.hideLoader();

  //       this.loadMkDrp = true;
  //     },
  //   });
  // };
  public getMarketOffice = async (): Promise<any> => {
    await this._auth.getMarketOffice().subscribe({
      next: (response) => {
        this.processMarketOfficeResponse(response['data']);
      },
      error: (error: Error) => {
        this._snack.notifyError(error.message, 'X');
        this._loader.hideLoader();
      },
      complete: () => {
        this._loader.hideLoader();
        this.loadMkDrp = true;
      },
    });
  };

  private processMarketOfficeResponse(data: any[]): void {
    let mkOfficeDetails = [];

    data.forEach((state) => {
      if (state.state_code === 'CA') {
        // Filter by state_code
        state.offices.forEach((office) => {
          mkOfficeDetails.push({
            office_name: office.office_name,
            zone_ids: office.zone_ids,
            market_office_id: office.market_office_id,
          });
        });
      }
    });

    mkOfficeDetails.sort((a, b) => a.office_name.localeCompare(b.office_name));

    this.MODrpdwn = mkOfficeDetails;
  }

  driverTrackBy(id, driver) {
    return driver.id;
  }
  /*
   * Method Name:search
   * Purpose:search report
   * Date:17th May, 2023
   */
  public search(): void {
    // Set loading indicators
    this.isLoading = true;

    this._loader.showLoader();

    // Prepare request parameters
    const reqParams = {
      params: {
        state: 'CA',
        drivers: this.driverIds,
        market_office: this.marketOfficeIds,
        st_dt: dateFormat(this.caFilterForm.value.st_dt),
        en_dt: dateFormat(this.caFilterForm.value.en_dt),
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    // Call the California Reports API
    this._CAService
      .getOLDCAReportsAPI(reqParams, this.reportVersion)
      .subscribe({
        next: (response: Response) => {
          if (response['error']) {
            // Handle API error
            this._snack.notifyError(response['error']['message']);
          } else {
            // Update the report data and review count
            this.caChildComp.report = response['result'];
            this.caChildComp.updateReviewCount(this.report);
          }
        },
        error: (err: Error) => {
          // Handle HTTP error
          this._snack.notifyError(err.message, 'X');
        },
        complete: () => {
          // Hide loaders and reset loading indicators
          this._loader.hideLoader();
          this.isLoading = false;
        },
      });
  }
}
