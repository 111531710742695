<!-- begin:: Page -->
<div class="m-grid m-grid--hor m-grid--root m-page login_main">
  <div
    class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2"
    id="m_login"
  >
    <div class="m-grid__item m-grid__item--fluid m-login__wrapper">
      <div class="m-login__container">
        <div class="m-login__logo">
          <a href="#">
            <img src="assets/img/logo.png" />
          </a>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<!-- end:: Page -->
