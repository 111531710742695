import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpAuthorizationHeaderInterceptor } from './http-authorization-header.interceptor';
import { SuccessInterceptor } from './http-success-handler.interceptor';
import { ErrorInterceptor } from './http-error-handler.interceptor';


export const HTTPInterceptorProvider = [
  {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: HttpAuthorizationHeaderInterceptor,
  },
  {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: SuccessInterceptor,
  },
  {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
  },
];
