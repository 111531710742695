import { appSettings } from '@app/configs';
import CryptoJS from 'crypto-js';
import * as moment from 'moment-timezone';

interface IValidationError {
  _fileName: string;
  _fileSize: number;
  _isMatchedExt: boolean;
  _isSizeExceeds: boolean;
}

/**
 * *Checking if a arg is object
 *
 * @param arg - object | array
 * @returns obecjt | boolean
 * @date 28 June 2022
 * @developer Apium
 */
export function isObject(arg: any) {
  return typeof arg === 'object' && arg !== null && !(arg instanceof Array)
    ? arg
    : false;
}

/**
 * *Encryptes user email
 *
 * @param data
 * @returns string
 * @date 28 June 2022
 * @developer Apium
 */
export function encryption(data: string): string | undefined {
  const SECRET = appSettings.cryptoSecret;

  const b64 = CryptoJS.AES.encrypt(data, SECRET).toString();
  const e64 = CryptoJS.enc.Base64.parse(b64);
  const eHex = e64.toString(CryptoJS.enc.Hex);

  return eHex;
}

/**
 * *Decryptes encrypted string
 *
 * @param cipherText
 * @returns number
 * @date 28 June 2022
 * @developer Apium
 */
export function decryption(cipherText: string): any {
  const SECRET = appSettings.cryptoSecret;

  const reb64 = CryptoJS.enc.Hex.parse(cipherText);
  const bytes = reb64.toString(CryptoJS.enc.Base64);
  const decrypt = CryptoJS.AES.decrypt(bytes, SECRET);
  const plain = decrypt.toString(CryptoJS.enc.Utf8);

  return JSON.parse(plain);
}

export function dateFormat(date?: string): string | null {
  if (!date) {
    // Handle the case when no input is received
    console.error('No date input received');
    return null;
  }

  const formattedDate = moment(date, 'YYYY-MM-DD', true).format('YYYY-MM-DD');

  if (formattedDate === 'Invalid date') {
    // Handle the error, you can return null or throw an exception
    console.error('Invalid date format');
    return null;
  }

  return formattedDate;
}

export function futureOrderDateFormat(itemTime, itemTimezone) {
  return moment.unix(itemTime).tz(itemTimezone).format('hh:mm A (MM/DD)') || '';
}
// Function to encrypt a number by adding a shift value
export function simpleEncryptNumber(id: number, shift: number): number {
  return id + shift;
}

// Function to decrypt a number by subtracting the shift value
export function simpleDecryptNumber(
  encryptedId: number,
  shift: number
): number {
  return encryptedId - shift;
}
