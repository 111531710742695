<div
  class="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-light m-aside-left--fixed m-aside-left--offcanvas m-aside-left--minimize m-brand--minimize m-footer--push m-aside--offcanvas-default"
>
  <!-- begin:: Page -->
  <div class="m-grid m-grid--hor m-grid--root m-page">
    <!-- BEGIN: Header -->
    <apium-header-layout></apium-header-layout>
    <!-- END: Header -->
    <!-- begin::Body -->
    <div
      class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body"
    >
    
      <!-- BEGIN: Left Aside -->
      <apium-sidebar-layout></apium-sidebar-layout>
      
      <!-- END: Left Aside -->
      <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <!-- BEGIN: Subheader -->
        <!-- <div class="m-subheader">
          <div class="d-flex align-items-center">
            <div class="mr-auto">
              <h3 class="m-subheader__title">Dashboard</h3>
            </div>
            <div></div>
          </div>
        </div> -->
        <!-- END: Subheader -->
        <!-- body content  -->
        <router-outlet></router-outlet>

        <!-- end::Footer -->
      </div>
      <!-- end:: Page -->
      <!-- begin::Scroll Top -->
      <div
        class="m-scroll-top m-scroll-top--skin-top"
        data-toggle="m-scroll-top"
        data-scroll-offset="500"
        data-scroll-speed="300"
      >
        <i class="la la-arrow-up"></i>
      </div>
      <!-- end::Scroll Top -->
    </div>
    <apium-footer-layout></apium-footer-layout>
  </div>
</div>
