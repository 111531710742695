import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { environment } from '@env/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { MaterialModule } from './material/material-module';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { OrderByPipe } from './pipes/orderby.pipe';
import { FormattedTimePipe } from './pipes/formatted-time.pipe';
import { TpdatetimePipe } from './pipes/tpdatetime.pipe';
import { ClickOutsideDirective } from './directives/clickoutside.directive';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TimeZoneConvertPipe } from './pipes/time-zone-convert.pipe';
import { TaskBatchPipe } from './pipes/task-batch.pipe';
import { TaskStatusPipe } from './pipes/task-status.pipe';
import { TimeZoneConverted2Pipe } from './pipes/time-zone-converted2.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { DataFilterPipe } from './pipes/data-filter.pipe';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';

import { YesNoPipe } from './pipes/yes-no.pipe';
import { CaliforniaReportsComponent } from './components/california-reports/california-reports.component';

import { LegacyReportFiltersComponent } from './components/legacy-report-filters/legacy-report-filters.component';
import { NewReportFiltersComponent } from './components/new-report-filters/new-report-filters.component';
import { MatSelectSearchModule } from 'mat-select-search';
import { ZoneFilterPipe } from './pipes/zone-filter.pipe';
import { TreeComponent } from './components/tree/tree.component';
import { CommonFilterComponent } from './components/common-filter/common-filter.component';
import { MultiSelectSearchComponent } from './components/multi-select-search/multi-select-search.component';
import { MkFilterComponent } from './components/mk-filter/mk-filter.component';
import { MatSelectSearchComponent } from './components/mat-select-search/mat-select-search.component';
import { NotePipe } from './pipes/note.pipe';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  MaterialModule,
  NgMultiSelectDropDownModule,
  NgxPaginationModule,
  MatSelectSearchModule,
];
const COMPONENTS = [
  DatePickerComponent,
  MultiSelectComponent,
  CaliforniaReportsComponent,
  NewReportFiltersComponent,
  LegacyReportFiltersComponent,
  TreeComponent,
  CommonFilterComponent,
  MultiSelectSearchComponent,
  MkFilterComponent,
  MatSelectSearchComponent
];
const PIPES = [
  OrderStatusPipe,
  SafePipe,
  OrderByPipe,
  FormattedTimePipe,
  TpdatetimePipe,
  TimeZoneConvertPipe,
  TaskBatchPipe,
  TaskStatusPipe,
  TimeZoneConverted2Pipe,
  DataFilterPipe,
  YesNoPipe,
  ZoneFilterPipe,
];
const DIRECTIVES = [ClickOutsideDirective];

@NgModule({
  declarations: [
    OrderStatusPipe,
    SafePipe,
    OrderByPipe,
    FormattedTimePipe,
    TpdatetimePipe,
    ClickOutsideDirective,
    DatePickerComponent,
    TimeZoneConvertPipe,
    TaskBatchPipe,
    TaskStatusPipe,
    TimeZoneConverted2Pipe,
    DataFilterPipe,
    MultiSelectComponent,
    YesNoPipe,
    CaliforniaReportsComponent,
    NewReportFiltersComponent,
    LegacyReportFiltersComponent,
    ZoneFilterPipe,
    TreeComponent,
    CommonFilterComponent,
    MultiSelectSearchComponent,
    MkFilterComponent,
    MatSelectSearchComponent,
    NotePipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    MaterialModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule,
  ],
  exports: [...MODULES, ...COMPONENTS, ...PIPES, ...DIRECTIVES],
})
export class SharedModule {}
