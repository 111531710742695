import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import apiEndPointsConfig from '@app/configs/api-end-points.config';
import { HttpService } from '@app/core/http';
import { LoaderService } from '@app/shared/modules/loader';
import { SnackBarService } from '@app/shared/services/snack-bar.service';
import { TResponse } from '@app/shared/types/response.types';
import { dateFormat } from '@app/shared/utilities';

/**
 * Component for managing filters in reports.
 */
@Component({
  selector: 'mk-filter',
  templateUrl: './mk-filter.component.html',
  styleUrls: ['./mk-filter.component.scss'],
})
export class MkFilterComponent implements OnInit, OnDestroy {
  /** List of market offices. */
  public mkOfficeList: any[] = [];
  /** List of drivers. */
  public driversList: any[] = [];
  /** Form group for managing filter inputs. */
  public form: FormGroup;

  /** State code for the filter. */
  @Input() stateCode: string = '';
  /** Version for the filter. */
  @Input() version: string = '';
  /** Event emitted when filters are applied. */
  @Output() filterApplied = new EventEmitter<any>();

  /** Subject to manage unsubscription of observables. */
  private unsubscribe$ = new Subject<void>();
  /** Minimum date allowed for filter. */
  minDate: Date;
  /** Maximum date allowed for filter. */
  maxDate: Date;

  formSubmitted = false;

  /**
   * Initializes the MkFilterComponent with services.
   * @param fb FormBuilder for creating the form.
   * @param loader LoaderService to show/hide loader.
   * @param snackBar SnackBarService for displaying notifications.
   * @param http HttpService for making HTTP requests.
   */
  constructor(
    private fb: FormBuilder,
    private loader: LoaderService,
    private snackBar: SnackBarService,
    private http: HttpService
  ) {
    this.form = this.fb.group({
      market_office: [null, Validators.required],
      drivers: [null, Validators.required],
      startDate: [null, [Validators.required, this.dateValidator('start')]],
      endDate: [null, [Validators.required, this.dateValidator('end')]],
    });
  }

  /**
   * Initializes the component by setting date range and loading market office list.
   */
  ngOnInit(): void {
    this.setDateRangeBasedOnStateCode();
    this.loadMkOfficeList();
  }

  /**
   * Cleans up subscriptions when the component is destroyed.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Sets the minimum and maximum date range based on the version.
   */
  /**
   * Sets the minimum and maximum date range based on the state code.
   */
  private setDateRangeBasedOnStateCode(): void {
    if (this.stateCode === 'NV') {
      this.minDate = new Date('2021-06-07');
      this.maxDate = new Date('2023-12-31');
    } else {
      switch (this.version) {
        case 'v6':
          this.minDate = new Date(2023, 5, 19); // June 19, 2023
          this.maxDate = new Date(2023, 11, 31); // December 31, 2023
          break;
        case 'v5':
          this.minDate = new Date(2023, 1, 20); // February 20, 2023
          this.maxDate = new Date(2023, 5, 18); // June 18, 2023
          break;
        case 'v4':
          this.minDate = new Date(2023, 0, 9); // January 9, 2023
          this.maxDate = new Date(2023, 1, 19); // February 19, 2023
          break;
        case 'v3':
          this.minDate = new Date(2022, 11, 5); // December 5, 2022
          this.maxDate = new Date(2023, 0, 8); // January 8, 2023
          break;
        case 'v2':
          this.minDate = new Date(2022, 2, 7); // March 7, 2022
          this.maxDate = new Date(2022, 11, 4); // December 4, 2022
          break;
        case 'v1':
          this.minDate = new Date(2021, 5, 7); // June 7, 2021
          this.maxDate = new Date(2022, 2, 6); // March 6, 2022
          break;
        default:
          // Handle the case where the version is not matched
          this.minDate = new Date(2024, 0, 1); // default min date
          this.maxDate = new Date(2024, 0, 31); // default max date
          break;
      }
    }
  }

  /**
   * Loads the market office list based on the state code.
   */
  private loadMkOfficeList(): void {
    this.loader.showLoader();
    this.http
      .get<TResponse>(`${apiEndPointsConfig.marketOffice}/${this.stateCode}`, {
        useUrlPrefix: true,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: TResponse) => {
          this.mkOfficeList = response.dataset['market_offices_list'] || [];
        },
        error: (error) => {
          this.snackBar.notifyError(error.message);
        },
        complete: () => {
          this.loader.hideLoader();
        },
      });
  }

  /**
   * Fetches drivers based on selected zones.
   * @param selectedZones Array of selected zone IDs.
   */
  private getDrivers(selectedZones: number[]): void {
    this.loader.showLoader();
    const params = {
      filter_by: 'market_office',
      zone_id: {
        [this.stateCode]: selectedZones.join(','),
      },
    };
    this.http
      .post<TResponse>(`${apiEndPointsConfig.orgMoDrivers}`, params, {
        useUrlPrefix: true,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: TResponse) => {
          this.driversList = response.dataset['master_drivers_list'] || [];
          this.form.get('drivers')?.enable();
        },
        error: (error) => {
          this.snackBar.notifyError(error.message);
        },
        complete: () => {
          this.loader.hideLoader();
        },
      });
  }

  /**
   * Handles the selected options and updates the form.
   * @param event The selected options.
   * @param controlName The form control name.
   */
  onSelectedOptions(event: any, controlName: string): void {
    if (controlName === 'market_office') {
      const selectedZones = event
        .map((office: any) => office.zones.map((zone: any) => zone.id))
        .flat();

      this.getDrivers(selectedZones);
    }

    this.form.get(controlName)?.setValue(event);
  }

  /**
   * Emits the filter data when the form is submitted.
   */
  onSubmit(): void {
    if (
      dateFormat(this.form.get('startDate')?.value) >
      dateFormat(this.form.get('endDate')?.value)
    ) {
      this.snackBar.notifyError('Start date cannot be greater than end date.');
      return;
    }

    this.formSubmitted = true; // Set this flag to true to show custom component validation
    this.form.markAllAsTouched(); // Mark all fields as touched to trigger validation messages

    if (this.form.valid) {
      const formData = this.form.value;

      // Emit the valid form data
      this.filterApplied.emit(formData);
    }
  }

  /**
   * Custom validator to ensure date is within the allowed range.
   * @param type The type of date ('start' or 'end').
   * @returns A validator function.
   */
  private dateValidator(
    type: 'start' | 'end'
  ): (control: AbstractControl) => { [key: string]: any } | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value ? new Date(control.value) : null;
      if (type === 'start') {
        return value && value < this.minDate ? { minDate: true } : null;
      } else {
        return value && value > this.maxDate ? { maxDate: true } : null;
      }
    };
  }
}
