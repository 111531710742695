import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'tpdatetime',
})
export class TpdatetimePipe implements PipeTransform {
  transform(
    complete_after: number | string,
    tz: string,
    complete_before: number | string
  ): string {
    if (!complete_after || !tz || !complete_before) {
      return '-';
    }

    const afterTime = moment
      .unix(Number(complete_after))
      .tz(tz)
      .format('hh:mm A');
    const beforeTime = moment
      .unix(Number(complete_before))
      .tz(tz)
      .format('hh:mm A (MM/DD)');

    return `${afterTime}-${beforeTime}`;
  }
}
