const AUTH_PHASE = {
  login: `auth/sign-in`,
  logout: `v2/auth/logout`,
  getsubfranchiseinfo: `v2/auth/get-sub-franchisee-info`,
  franchisepermission: `v2/auth/franchise_service/auth/get-permission`,
  getallfranchisepermisssion: `v2/auth/permission`,
  getalloldFranchisePermisssion: `auth/permission`,
  forgotpassword: `auth/forgot-password`,
  resetpassword: `auth/reset-password`,
  timezone: `v2/auth/timezone`,
  updatetimezone: `v2/auth/update-timezone`,
  getMarketOffice: `auth/get-market-office`,
  permissions: 'permissions',
};

const DASHBOARD_PHASE = {
  driverList: `v2/driver/list`,
  activeDrivers: 'drivers/active',
  getAllZone: `v2/auth/auth/get-franchisee-zone/`,
  getDriverDetails: `v2/driver/info/`,
  getAllDrivers: `v2/driver/info/`,
  getDriverLocaton: `v2/driver/location/`,
  getTPOrderFullDetails: `v2/order`,
};

const FUTURE_ORDERS_PHASE = {
  futureOrders: 'orders/future',
  getAllFutureOrders: `v2/order/future-list`,
  allCateringOrders: `v2/order/list`,
  orderDetails: `v2/order_details/`,
  cateringOrderDetails: `v2/catering`,
};

const PROFILE_PHASE = {
  contacts: `contacts`,
  updateProfile: `managers/update-profile`,
  profileInfo: `managers/profile`,
  franchiseList: `v2/auth/sub-account-list`,
  changePassword: `managers/update-password`,
  getInviteeList: `v2/sub-account/invite-list`,
  deleteSubAccount: `v2/sub-account/delete/`,
  getSubAccount: `v2/sub-account/get-info/`,
  getSubAccntDetInfo: `v2/sub-account/get-info/`,
  updateSuAccountDetails: `v2/sub-account/edit`,
  inviteSubAccount: `v2/sub-account/invite`,
  subAccounts: 'sub-accounts',
  invitations: 'invitations',
};

const DRIVER = {
  driver_list: `v2/driver/mofc-driver-list`,
  old_driver_list: `driver/mofc-driver-list`,
  driver_info: `v2/driver/info`,
  drivers: 'drivers',
};

const REPORTS = {
  otherStates: 'reports/other',
  marketOffice: 'market-offices',
  californiaReports: 'reports/california',
  lateOrder: 'orders/late',
  late_order_list: `v2/order/late-orders`,
  order_details: `v2/order/late-order`,
  ca_report_list: `v2/reports/CA/`,
  old_ca_report_list: `reports/CA/`,
  other_reports: `v2/reports/NV/list`,
  old_other_reports: `reports/NV/list`,
  MARKET_OFFICE: 'v2/getMarketOfficeByState',
  DRIVER_LIST: 'v2/getdriverList',
  CA_REPORT: 'v2/raleys-getDriverreportList',
  OLD_CA_REPORT: 'raleys-getDriverreportList',
  STATES: 'v2/getstatelist',
  OS_REPORTS: 'v2/getDriverreportList',
};

const TASK = {
  taskList: `v2/task/search`,
  getTaskDetails: `v2/task/search`,
  tasks: 'tasks',
};

const COMMON_PHASE = {
  organizations: 'organizations/master',
  states: 'states/master',
  zones: 'zones/master',
  orgMoDrivers: 'drivers/master',
};

export default {
  ...AUTH_PHASE,
  ...DASHBOARD_PHASE,
  ...FUTURE_ORDERS_PHASE,
  ...PROFILE_PHASE,
  ...DRIVER,
  ...REPORTS,
  ...TASK,
  ...COMMON_PHASE,
};
