<nav class="navbar navbar-expand-lg navbar-light fixed-top">
  <div class="logo_m">
    <a class="navbar-brand" href="#">
      <img src="assets/img/logo.png" alt="Logo" class="logo" />
    </a>
  </div>
  <div class="m-header__title title_head">
    <h3 class="m-header__title-text">Manager</h3>
  </div>
  <div class="left_head ml-auto">
    <div class="d-lg-none">
      <mat-icon (click)="mobileMenuOpen()">menu</mat-icon>
    </div>
    <div class="justify-content-end" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle pro_right"
            href="#"
            id="profileDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="flaticon-user-ok"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="profileDropdown"
          >
            <div class="dropdown-item no-hover">
              <h5 class="m-0">
                <mat-icon
                  class="icon text-success online"
                  style="font-size: 10px"
                  >lens</mat-icon
                >
                {{ user.first_name }} {{ user.last_name }}
              </h5>
            </div>
            <div class="dropdown-item no-hover">
              <i class="m-nav__link-icon flaticon-support"></i> {{ user.email }}
            </div>
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              routerLink="/{{ routes.PROFILE }}"
              style="cursor: pointer"
            >
              <i class="m-nav__link-icon flaticon-profile-1"></i> My Profile
            </a>
            <a
              class="dropdown-item power"
              (click)="logout($event)"
              style="cursor: pointer"
            >
              <i class="m-nav__link-icon flaticon-logout"></i> Logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
