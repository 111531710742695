import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication';
import { DriverService } from '@app/pages/driver/services/driver.service';
import { CaliforniaReportService } from '@app/pages/reports/services/california-report.service';
import {
  IDrivers,
  IOrganization,
  IState,
  IZone,
} from '@app/shared/interfaces/common.interface';
import { LoaderService } from '@app/shared/modules/loader';
import { MkOfficeService } from '@app/shared/services/mk-office.service';
import { SnackBarService } from '@app/shared/services/snack-bar.service';
import { Subscription } from 'rxjs';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { dateFormat } from '@app/shared/utilities';
import { CaliforniaReportsComponent } from '../california-reports/california-reports.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { appSettings } from '@app/configs';

@Component({
  selector: 'new-report-filters',
  templateUrl: './new-report-filters.component.html',
  styleUrls: ['./new-report-filters.component.scss'],
})
export class NewReportFiltersComponent implements OnInit {
  @Input() properties: any;
  @ViewChild(DatePickerComponent, { static: true })
  datePickerComp: DatePickerComponent;
  @ViewChild(CaliforniaReportsComponent, { static: true })
  caChildComp: CaliforniaReportsComponent;

  organizations: IOrganization[];
  selectedOrganizationId: number;
  states: IState[];
  selectedState: IState;
  formattedData: IOrganization[];
  zones: IZone[];
  selectedZone: IZone[];
  selectionAllowed = true;
  permissionLoader = true;
  reportForm: FormGroup;
  private subscriptions: Subscription[] = [];
  isLoading = false;
  zoneIds: string[] = []; // The ids of the zones.
  driverList: IDrivers[];
  selectedDriverIds: number[] = [];
  minDateSt: Date = new Date();
  maxDateSt: Date = new Date();
  minDateEn: Date = new Date();
  maxDateEn: Date = new Date();
  reportVersion = '';
  reviewCount = false;
  report: any;
  name = '';
  dropdownSettingsForZones: IDropdownSettings = {};
  dropdownSettingsForDrivers: IDropdownSettings = {};

  constructor(
    private authService: AuthenticationService,
    private mkOfficeService: MkOfficeService,
    private formBuilder: FormBuilder,
    private _snack: SnackBarService,
    private _loader: LoaderService,
    private _CAService: CaliforniaReportService,
    private _driverService: DriverService
  ) {
    this.reportForm = this.formBuilder.group({
      organizations: ['', Validators.required],
      state: ['', Validators.required],
      zone: ['', Validators.required],
      driver: ['', Validators.required],
      st_dt: ['', Validators.required],
      en_dt: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadOrganizationData();

    // Setting the dropdown settings for zones.
    this.dropdownSettingsForZones = {
      singleSelection: false,
      idField: 'zone_id',
      textField: 'zone_name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      selectAllText: 'Select All', // Add this line
      unSelectAllText: 'Unselect All', // Add this line
    };

    // Setting the dropdown settings for drivers.
    this.dropdownSettingsForDrivers = {
      singleSelection: false,
      idField: 'id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      defaultOpen: false,
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['properties'] && changes['properties'].currentValue) {
      const { report, name, minDateSt, maxDateSt, minDateEn, maxDateEn } =
        changes['properties'].currentValue;
      this.reportVersion = `${report}`;
      this.name = name;
      this.minDateSt = minDateSt || new Date();
      this.maxDateSt = maxDateSt || new Date();
      this.minDateEn = minDateEn || new Date();
      this.maxDateEn = maxDateEn || new Date();
    }
  }

  loadOrganizationData(): void {
    this.subscriptions.push(
      this.authService.getAllFranchiseRelatedZone().subscribe(
        (data) => {
          this.formattedData =
            this.mkOfficeService.getFormattedOrganizationDataWithStatesAndZones(
              data
            );

          // Sort the formattedData in ascending order
          this.formattedData.sort((a, b) => {
            if (a.org_name < b.org_name) {
              return -1;
            }
            if (a.org_name > b.org_name) {
              return 1;
            }
            return 0;
          });

          this.permissionLoader = this.formattedData.length > 0;
          this.organizations = this.formattedData;
          this.updateStatesForCheckedOrganization();
          this.patchCheckedOrganization();
        },
        (error) => {
          this._snack.notifyError(error.message);
        },
        () => {
          this.permissionLoader = false;
        }
      )
    );
  }

  updateStatesForCheckedOrganization(): void {
    const checkedOrganization = this.formattedData.find((org) => org.checked);
    if (checkedOrganization) {
      // Sort the states in ascending order
      checkedOrganization.states.sort((a, b) => {
        if (a.state_name < b.state_name) {
          return -1;
        }
        if (a.state_name > b.state_name) {
          return 1;
        }
        return 0;
      });

      this.states = checkedOrganization.states;
    }
  }

  patchCheckedOrganization(): void {
    const checkedOrganization = this.formattedData.find((org) => org.checked);
    if (checkedOrganization) {
      this.reportForm.patchValue({
        organizations: checkedOrganization.org_id,
      });
    }
  }

  onOrganizationSelectionChange(orgId: number): void {
    this.reportForm.get('state').reset();
    this.reportForm.get('zone').reset();
    this.reportForm.get('driver').reset();
    const selectedOrganization = this.formattedData.find(
      (org) => org.org_id === orgId
    );
    if (selectedOrganization) {
      this.states = selectedOrganization.states.filter(
        (state) => state.state_code === 'CA'
      );
      this.selectedOrganizationId = selectedOrganization.org_id;
    }
  }

  onStateSelectionChange(stateId: number): void {
    this.reportForm.get('zone').reset();
    this.reportForm.get('driver').reset();
    this.selectedState = this.states.find(
      (state) => state.state_id === stateId
    );

    if (this.selectedState) {
      // Sort the zones in ascending order
      this.selectedState.zones.sort((a, b) => {
        if (a.zone_name < b.zone_name) {
          return -1;
        }
        if (a.zone_name > b.zone_name) {
          return 1;
        }
        return 0;
      });

      this.zones = this.selectedState.zones;
    } else {
      this.zones = [];
    }
  }

  // 🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸
  //          ✨✨✨ ZONES ✨✨✨
  // 🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸🌸
  selectOneZone(item: any): void {
    this.selectedDriverIds = [];
    this.reportForm.get('driver').reset();
    this.addToZoneIds(item.zone_id);
  }

  deselectOneZone(item: any): void {
    this.selectedDriverIds = [];
    this.reportForm.get('driver').reset();
    this.removeFromZoneIds(item.zone_id);
  }

  selectAllZones(items: any[]): void {
    this.reportForm.get('driver').reset();
    this.selectedDriverIds = [];
    this.zoneIds = items.map((i) => i.zone_id);
    this.updateDriverList();
  }

  onDeSelectAllZones(items: any[]): void {
    this.selectedDriverIds = [];
    this.reportForm.get('driver').reset();
    this.zoneIds = [];
    this.updateDriverList();
  }

  private addToZoneIds(zoneId: any): void {
    this.zoneIds.push(zoneId);
    this.updateDriverList();
  }

  private removeFromZoneIds(zoneId: any): void {
    const index = this.zoneIds.indexOf(zoneId);
    if (index !== -1) {
      this.zoneIds.splice(index, 1);
      this.updateDriverList();
    }
  }

  private updateDriverList(): void {
    if (this.zoneIds.length !== 0) {
      this.getDriverList(this.zoneIds);
    }
  }
  // ===========================================

  // ================DRIVERS==============
  selectOneDriver(driver: any): void {
    this.selectedDriverIds.push(driver.id);
  }

  deselectOneDriver(driver: any): void {
    const index = this.selectedDriverIds.indexOf(driver.id);
    if (index !== -1) {
      this.selectedDriverIds.splice(index, 1);
    }
  }

  selectAllDrivers(drivers: any[]): void {
    const allDriverIds = drivers.map((driver) => driver.id);
    this.selectedDriverIds = allDriverIds;
  }

  deselectAllDrivers(): void {
    this.selectedDriverIds = [];
  }

  // =====================================

  getDriverList(zoneIds: string[]): void {
    this.reportForm.get('driver').reset();
    this._loader.showLoader();
    const data = {
      params: {
        limit: 100,
        page: 1,
        search_text: '',
        zones: zoneIds.map(String),
      },
    };
    this._driverService.driverListAPI(data).subscribe({
      next: (response: any) => {
        if (response.error) {
          this._snack.notifyError(response.error.message, 'X');
        } else {
          this.driverList = response.result?.drivers;
          if (response.error)
            this._snack.notifyError(response.error.message, 'X');
        }
      },
      error: (err: Error) => {
        this._snack.notifyError(err.message, 'X');
      },
      complete: () => {
        this._loader.hideLoader();
      },
    });
  }

  search(): void {
    this.isLoading = true;
    this._loader.showLoader();
    const reqParams = {
      params: {
        state_id: this.selectedState.state_id,
        state_code: this.selectedState.state_code,
        drivers: this.selectedDriverIds,
        org_id: this.selectedOrganizationId,
        zones: this.zoneIds,
        st_dt: dateFormat(this.reportForm.value.st_dt),
        en_dt: dateFormat(this.reportForm.value.en_dt),
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
    this._CAService.getCAReportsAPI(reqParams, this.reportVersion).subscribe({
      next: (response: any) => {
        if (response.error) {
          this._snack.notifyError(response.error.message);
        } else {
          this.caChildComp.report = response['result'];
          this.caChildComp.updateReviewCount(this.report);
        }
      },
      error: (err: Error) => {
        this._snack.notifyError(err.message, 'X');
      },
      complete: () => {
        this._loader.hideLoader();
        this.isLoading = false;
      },
    });
  }
}
// ..
