<div
  id="m_aside_left"
  class="m-grid__item m-aside-left m-aside-left--skin-light"
>
  <button
    class="m-aside-left-close m-aside-left-close--skin-light"
    id="m_aside_left_close_btn"
    (click)="closeMenu()"
  >
    <i class="la la-close"></i>
  </button>
  <!-- BEGIN: Aside Menu -->
  <div
    id="m_ver_menu"
    class="added-cart-wrap m-aside-menu m-aside-menu--skin-light m-aside-menu--submenu-skin-light"
    data-menu-vertical="true"
    data-menu-scrollable="true"
    data-menu-dropdown-timeout="500"
  >
    <ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
      <!-- dashboard  -->
      <li
        class="m-menu__item m-menu__item--submenu m-menu__item--hover"
        aria-haspopup="true"
        data-menu-submenu-toggle="hover"
        routerLinkActive="active"
      >
        <a
          routerLink="/{{ routes.DASHBOARD }}"
          class="m-menu__link m-menu__toggle"
        >
          <i class="m-menu__link-icon flaticon-dashboard">
            <span>Dashboard</span>
          </i>
          <span class="m-menu__link-text"> Dashboard </span>
          <i class="m-menu__ver-arrow la la-angle-right"></i>
        </a>
      </li>
      <!-- task module  -->
      <li
        class="m-menu__item m-menu__item--submenu m-menu__item--hover"
        aria-haspopup="true"
        data-menu-submenu-toggle="hover"
        routerLinkActive="active"
      >
        <a routerLink="/{{ routes.TASKS }}" class="m-menu__link m-menu__toggle">
          <i class="m-menu__link-icon flaticon-list">
            <span> All Tasks</span>
          </i>
          <span class="m-menu__link-text"> All Tasks </span>
          <i class="m-menu__ver-arrow la la-angle-right"></i>
        </a>
      </li>
      <!-- driver module  -->

      <li
        class="m-menu__item m-menu__item--submenu m-menu__item--hover"
        aria-haspopup="true"
        data-menu-submenu-toggle="hover"
        routerLinkActive="active"
      >
        <a
          routerLink="/{{ routes.DRIVER }}"
          class="m-menu__link m-menu__toggle"
        >
          <i class="m-menu__link-icon flaticon-user">
            <span>Drivers</span>
          </i>
          <span class="m-menu__link-text"> Drivers </span>
          <i class="m-menu__ver-arrow la la-angle-right"></i>
        </a>
      </li>
      <!-- report module  -->
      <li
        class="m-menu__item m-menu__item--submenu m-menu__item--hover"
        aria-haspopup="true"
        data-menu-submenu-toggle="hover"
        routerLinkActive="active"
        [ngClass]="reportSubMenu ? 'active' : ''"
        (click)="reportSubMenu = !reportSubMenu"
      >
        <a class="m-menu__link m-menu__toggle">
          <i class="m-menu__link-icon flaticon-file">
            <span
              >Reports
              <i class="m-menu__ver-arrow la la-angle-right d-block"></i
            ></span>
          </i>
          <span class="m-menu__link-text"> Reports </span>
          <i class="m-menu__ver-arrow la la-angle-right"></i>
        </a>
      </li>

      <ng-container *ngIf="reportSubMenu">
        <div class="sub_menu">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.LATE_REPORT }}"
          >
            <span class="m-menu__link-text"> Late Reports</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>

        <div class="sub_menu sub_menu_new sub_menu_label">
          <span class="m-menu__link-text">California Reports</span>
          <i class="m-menu__ver-arrow la la-angle-right"></i>
        </div>
        <div class="sub_menu sub_menu_new">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.V10 }}"
          >
            <span class="m-menu__link-text">Report 07.01.24 to Present</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>
        <div class="sub_menu sub_menu_new">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.V9 }}"
          >
            <span class="m-menu__link-text">Report 05.20.24 to 06.30.24</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>

        <div class="sub_menu sub_menu_new">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.V7 }}"
          >
            <span class="m-menu__link-text">Report 01.01.24 to 05.19.24</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>
        <div class="sub_menu sub_menu_new">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.V6 }}"
          >
            <span class="m-menu__link-text">Report 06.19.23 to 12.31.23</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>
        <div class="sub_menu sub_menu_new">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.V5 }}"
          >
            <span class="m-menu__link-text">Report 02.20.23 to 06.18.23</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>
        <div class="sub_menu sub_menu_new">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.V4 }}"
          >
            <span class="m-menu__link-text">Report 01.09.23 to 02.19.23</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>

        <div class="sub_menu sub_menu_new">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.V3 }}"
          >
            <span class="m-menu__link-text">Report 12.05.22 to 01.08.23</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>

        <div class="sub_menu sub_menu_new">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.V2 }}"
          >
            <span class="m-menu__link-text">Report 03.07.22 to 12.04.22</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>

        <div class="sub_menu sub_menu_new">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.V1 }}"
          >
            <span class="m-menu__link-text">Report 06.07.21 to 03.06.22</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>

        <div class="sub_menu sub_menu_new sub_menu_label">
          <span class="m-menu__link-text">Others Report</span>
          <i class="m-menu__ver-arrow la la-angle-right"></i>
        </div>
        <div class="sub_menu sub_menu_new_n">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.OTHER_STATES_V2 }}"
          >
            <span class="m-menu__link-text">Report 01.01.24 to Present</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>
        <div class="sub_menu sub_menu_new_n">
          <a
            routerLinkActive="actiive"
            routerLink="/{{ routes.REPORTS }}/{{ routes.OTHER_STATES_V1 }}"
          >
            <span class="m-menu__link-text">Report 06.07.21 to 12.31.23</span>
            <i class="m-menu__ver-arrow la la-angle-right"></i>
          </a>
        </div>
      </ng-container>
    </ul>
  </div>
  <!-- END: Aside Menu -->
</div>

<div [ngClass]="{ 'm-aside-left-overlay': overlayLeft === true }"></div>
