<div class="multiselect-dropdown position-relative">
  <!-- Selected Options Display -->
  <div class="selected-options" (click)="toggleDropdown($event)">
    <span>{{ selectedLabel }}</span>
  </div>

  <!-- Search Input -->
  <input
    type="text"
    class="form-control"
    [placeholder]="required ? placeholder + ' *' : placeholder"
    [(ngModel)]="searchTerm"
    (input)="onInputChange($event)"
    (click)="toggleDropdown($event)"
    [ngClass]="{
      'required-placeholder':
        required && formSubmitted && !hasSelectedOptions(),
      'error-border': formSubmitted && !hasSelectedOptions()
    }"
  />

  <!-- Dropdown List -->
  <ul
    *ngIf="isOpen"
    class="list-group position-absolute w-100 mt-1"
    aria-labelledby="dropdownMenuButton"
    [ngStyle]="{ display: isOpen ? 'block' : 'none' }"
  >
    <!-- Select All Option -->
    <li
      class="list-group-item d-flex align-items-center"
      *ngIf="filteredOptions.length"
    >
      <label class="container_ch">
        <input
          type="checkbox"
          class="me-2"
          [checked]="isAllSelected()"
          (change)="toggleSelectAll()"
        />
        Select All
        <span class="checkmark_ch"></span>
      </label>
    </li>
    <li
      *ngFor="let option of filteredOptions"
      class="list-group-item d-flex align-items-center"
    >
      <label class="container_ch">
        <input
          type="checkbox"
          class="me-2"
          [checked]="isSelected(option)"
          (change)="toggleSelection(option)"
        />
        {{ option.name }}
        <span class="checkmark_ch"></span>
      </label>
    </li>
  </ul>
</div>
