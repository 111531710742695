import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
// import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';

//Interceptor import
// import { HTTPInterceptorProvider } from './interceptors';

//Strategies import
import {
  PreloadModulesStrategy,
  NetworkAwarePreloadModulesStrategy,
} from './strategies';

//Service imports
import {
  TitleService,
  LoggerService,
  CommonService,
  WindowRefService,
} from '@core/services';
import { HttpService } from './http';

import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';
import { CookieService } from 'ngx-cookie-service';
import { HTTPInterceptorProvider } from './interceptors';
import { AuthenticationService } from './authentication';
import { AuthGuard, SecureOuterGuard } from './guards';

//Guard imports
// import { AuthGuard, SecureOuterPagesGuard } from './guards';

const PROVIDERS = [
  // DatePipe,
  AuthGuard,
  HttpService,
  TitleService,
  LoggerService,
  CommonService,
  WindowRefService,
  SecureOuterGuard,
  AuthenticationService,
  PreloadModulesStrategy,
  NetworkAwarePreloadModulesStrategy,
  CookieService,
];

@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule],
  exports: [RouterModule, HttpClientModule],
  providers: [...PROVIDERS, HTTPInterceptorProvider],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  /**
   * !Ensuring that CoreModule is only loaded into AppModule
   * *Looks for the module in the parent injector to see if it's already been loaded (only load once)
   *
   * @param module as parameter
   * @date 28 June 2022
   * @developer Apium
   */
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    _titleService: TitleService
  ) {
    super(parentModule);
    _titleService.init();
  }
}
