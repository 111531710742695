import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
  private statusMap: { [key: string]: string } = {
    ord_sched: 'Scheduled',
    ord_sched_cnfrm: 'Confirmed',
    ord_proc_hld: 'Queued',
    ord_proc_rstrnt: 'Processing',
    ord_proc_drv: 'Deploying Driver',
    ord_cmp: 'Complete',
    ord_cncled: 'Canceled',
    ord_cncling: 'Canceling',
    rstrnt_acpt_ord: 'Accepted',
    rstrnt_cmp_ord: 'Completed',
    rstrnt_drv_pkup: 'Driver Picked Up',
    drv_strt_rstrnt_tsk: 'To Restaurant',
    drv_cmp_rstrnt_tsk: 'Picked Up',
    drv_strt_cust_tsk: 'To Customer',
    drv_cmp_cust_tsk: 'Delivered',
    // For external orders
    tsk_proc_hld: 'Queued',
    tsk_proc_mrchnt: 'Processing',
    tsk_proc_drv: 'Deploying Driver',
    tsk_cmp: 'Completed',
    tsk_cncling: 'Canceling',
    tsk_cncled: 'Canceled',
    drv_strt_mrchnt_tsk: 'To Merchant',
    drv_cmp_mrchnt_tsk: 'Picked Up',
    drv_strt_recpnt_tsk: 'To Recipient',
    drv_cmp_recpnt_tsk: 'Delivered',
  };

  transform(value: any, args?: any): any {
    return this.statusMap[value] || '';
  }
}
