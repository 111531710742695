import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { environment } from '@env/environment';
import { isObject } from '@shared/utilities/utils';
import { TResponse } from '@app/shared/types/response.types';

type IRequestType = 'DELETE' | 'GET' | 'HEAD' | 'POST' | 'PATCH' | 'PUT';

interface IRequestOptions {
  useUrlPrefix?: boolean;
  headers?: any;
  observe?: 'body' | 'events' | 'response';
  reportProgress?: boolean;
}

@Injectable()
export class HttpService {
  private baseUrl: string = environment.BASE_URL; // Base URL from environment

  constructor(private _http: HttpClient) {}

  private request<T>(
    method: IRequestType,
    url: string,
    params?: any,
    options?: IRequestOptions
  ): Observable<T> {
    let data: any;
    let reqUrl = url;
    let reqOptions: any = { useUrlPrefix: true };

    if (isObject(options)) reqOptions = Object.assign(reqOptions, options);

    if (reqOptions.headers) {
      reqOptions.headers = new HttpHeaders(reqOptions.headers);
    }

    if (isObject(params)) {
      if (params instanceof FormData) {
        data = params;
        reqOptions.reportProgress = reqOptions.reportProgress || true;
      } else {
        data = params;
      }
    }

    if (reqOptions.useUrlPrefix === true) {
      reqUrl = `${this.baseUrl}${url}`;
    }

    if (data) {
      reqOptions.body = data;
    }

    reqOptions.observe = 'events'; // Ensure events are being observed

    // Final Request
    return this._http.request<TResponse>(method, reqUrl, reqOptions).pipe(
      filter((event: HttpEvent<TResponse>): event is HttpResponse<TResponse> => {
        return event instanceof HttpResponse;
      }),
      map((response: HttpResponse<TResponse>) => {
        // Cast the response body to unknown first, then to T
        return response.body as unknown as T;
      })
    );
  }

  public post<T>(
    url: string,
    params?: any,
    options?: IRequestOptions
  ): Observable<T> {
    return this.request<T>('POST', url, params, options);
  }

  public put<T>(
    url: string,
    params?: any,
    options?: IRequestOptions
  ): Observable<T> {
    return this.request<T>('PUT', url, params, options);
  }

  public patch<T>(
    url: string,
    params?: any,
    options?: IRequestOptions
  ): Observable<T> {
    return this.request<T>('PATCH', url, params, options);
  }

  public get<T>(url: string, options?: IRequestOptions): Observable<T> {
    return this.request<T>('GET', url, {}, options);
  }

  public delete<T>(
    url: string,
    params?: any,
    options?: IRequestOptions
  ): Observable<T> {
    return this.request<T>('DELETE', url, params, options);
  }
}
