export enum routes {
  //home
  HOME = '',
  //login
  LOGIN = 'login',
  //forgot password
  FORGOT_PASSWORD = 'forgot-password',
  //reset password
  RESET_PASSWORD = 'reset-password',
  //dashboard
  DASHBOARD = 'dashboard',
  //future orders
  FUTURE_ORDERS = 'future-orders',
  //profile
  PROFILE = 'profile',
  // sub account
  SUB_ACCOUNT = 'sub-account',
  //driver
  DRIVER = 'driver',
  DRIVER_LIST = 'list',
  //reports
  REPORTS = 'reports',
  LATE_REPORT = 'late',
  DRIVER_REPORTS = 'driver',
  OTHER_REPORTS = 'other-states',
  OTHER_STATES_V2 = 'v2',
  OTHER_STATES_V1 = 'v1',

  //tasks
  TASKS = 'tasks',
  TASKS_LIST = 'list',
  //california reports

  V10 = 'report-07_01_24-present', // Report 1st July, 2024 to Present
  V9 = 'report-05_20_24-06_30_24', // Report 20th May, 2024 to 30th June, 2024
  V8 = 'report-01_01_24-05_19_24', // Report 1st January, 2024 to 19th May, 2024
  V7 = 'report-01_01_24-05_19_24', // Existing version (kept as is, but should be distinct if relevant)
  V6 = 'report-02_18_23-12_21_23', // Report 18th February, 2023 to 21st December, 2023
  V5 = 'report-02_20_23-02_19_23', // Report 20th February, 2023 to 19th February, 2023
  V4 = 'report-01_09_23-02_19_23', // Report 9th January, 2023 to 19th February, 2023
  V3 = 'report-12_05_22-01_08_23', // Report 5th December, 2022 to 8th January, 2023
  V2 = 'report-03_07_22-12_04_22', // Report 7th March, 2022 to 4th December, 2022
  V1 = 'report-06_07_21-03_06_22', // Report 7th June, 2021 to 6th March, 2022
}
