import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(public _snackBar: MatSnackBar) {}

  private openSnackBar(message: string, action: string, panelClass: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: panelClass,
    });
  }

  notifySuccess(message: string, action = 'X') {
    this.openSnackBar(message, action, 'green-snackbar');
  }

  notifyError(message: string, action = 'X') {
    this.openSnackBar(message, action, 'red-snackbar');
  }

  notifyWarning(message: string, action = 'X') {
    this.openSnackBar(message, action, 'yellow-snackbar');
  }
}
