import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { routes } from '@app/configs';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication';
import { LoggerService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private routes: typeof routes = routes;
  constructor(
    private _authService: AuthenticationService,
    private router: Router,
    private _logger: LoggerService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isLoggedIn = this._authService.isLoggedIn();
    if (isLoggedIn) {
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page
    this._logger.error('Not authenticated, redirecting...');
    this.router.navigate([`/${routes.LOGIN}`]);
    return false;
  }
}
