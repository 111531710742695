import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import apiEndPointsConfig from '@app/configs/api-end-points.config';
import { AuthenticationService } from '@app/core/authentication';
import { HttpService } from '@app/core/http';
import { TResponse } from '@app/shared/types/response.types';
import { LoaderService } from '@app/shared/modules/loader';
import { SnackBarService } from '@app/shared/services/snack-bar.service';
import {
  MustMatch,
  validatePhoneNumber,
} from '@shared/validators/custom-validators.validators';

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  /** The form group for sign-up */
  signUpForm: UntypedFormGroup;

  /** The invitation code from the route parameters */
  invitationCode: string;

  /** The ID of the invitation details */
  id: number = 0;

  /** Flag to show or hide the form */
  showForm: boolean = false;

  /** Flag to toggle password visibility */
  showPassword: boolean = false;

  /** Flag to toggle confirm password visibility */
  showConfirmPassword: boolean = false;

  /** Flag to indicate if the form has been submitted */
  isSubmitted: boolean = false;

  /** Array to hold all active subscriptions for cleanup */
  private subscriptions: Subscription[] = [];

  /**
   * Initializes the component and injects required services.
   * @param router - The Angular Router for navigation.
   * @param formBuilder - The form builder service to create forms.
   * @param snackBar - The snack bar service for displaying notifications.
   * @param loader - The loader service to show/hide the loading indicator.
   * @param authService - The authentication service for user authentication.
   * @param activatedRoute - The activated route service for accessing route parameters.
   * @param httpService - The HTTP service for making API calls.
   */
  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private snackBar: SnackBarService,
    private loader: LoaderService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService
  ) {
    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe((params) => {
        this.invitationCode = params.get('code');
      })
    );
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    this.initializeSignUpForm();
    this.prefillFormIfInvitationExists();
  }

  /**
   * Initializes the sign-up form with form controls and validators.
   * @private
   */
  private initializeSignUpForm(): void {
    this.signUpForm = this.formBuilder.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        country_code: ['+1', Validators.required],
        phone: ['', [Validators.required, validatePhoneNumber]],
        password: ['', Validators.required],
        confirmpassword: ['', Validators.required],
      },
      {
        validators: MustMatch('password', 'confirmpassword'),
      }
    );
  }

  /**
   * Prefills the form fields if invitation details exist.
   * @private
   */
  private prefillFormIfInvitationExists(): void {
    const inviteInfo = this.activatedRoute.snapshot.data['inviteInfo'];
    if (inviteInfo && inviteInfo.dataset.invitation_details) {
      const { id, email, first_name, last_name } =
        inviteInfo.dataset.invitation_details;
      this.showForm = true;
      this.id = id;
      this.signUpForm.patchValue({ email, first_name, last_name });
    } else {
      this.showForm = false;
      this.loader.hideLoader();
    }
  }

  /**
   * Toggles the visibility of the password field.
   */
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Toggles the visibility of the confirm password field.
   */
  toggleConfirmPasswordVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  /**
   * Handles the submission of the sub-account sign-up form.
   */
  public acceptSubAccSignup(): void {
    this.isSubmitted = true;
    if (this.signUpForm.invalid) {
      return;
    }

    const signUpData = { ...this.signUpForm.value };
    delete signUpData.confirmpassword;

    this.loader.showLoader();
    const signUpSubscription = this.httpService
      .patch(`${apiEndPointsConfig.invitations}/${this.id}`, signUpData, {
        useUrlPrefix: true,
      })
      .subscribe({
        next: (response: TResponse) => {
          this.snackBar.notifySuccess(response.message);
          this.router.navigate(['login']);
        },
        error: (error: Error) => {
          this.loader.hideLoader();
          this.snackBar.notifyError(error.message);
        },
      });

    this.subscriptions.push(signUpSubscription);
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Unsubscribes from all active subscriptions to prevent memory leaks.
   */
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
