import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';

/**
 * Component for displaying and managing California reports.
 */
@Component({
  selector: 'california-reports',
  templateUrl: './california-reports.component.html',
  styleUrls: ['./california-reports.component.scss'],
})
export class CaliforniaReportsComponent implements OnChanges, OnDestroy {
  /**
   * Flag to indicate if there are reviews.
   * @type {boolean}
   */
  public reviewCount: boolean = false;

  /**
   * Name property (currently unused in the component).
   * @type {string}
   */
  name: string = '';

  /**
   * Reference to Object.values method.
   * @type {typeof Object.values}
   */
  objectValues = Object.values;

  /**
   * Reference to Object.keys method.
   * @type {typeof Object.keys}
   */
  objectKeys = Object.keys;

  /**
   * Placeholder for report data.
   * @type {any}
   */
  @Input() report: any;

  /**
   * Lifecycle hook called when the component is destroyed.
   */
  ngOnDestroy(): void {
    // Cleanup logic if needed
  }

  /**
   * Lifecycle hook called when any data-bound input property changes.
   * @param {SimpleChanges} changes - The changes to the input properties.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['report']) {
      // Log the current value of the report input
      this.updateReviewCount(changes['report'].currentValue);
    }
  }

  /**
   * Convert a timestamp to a formatted time string.
   * @param {number} timestamp - The timestamp to convert.
   * @returns {string} - The formatted time string in 'HH:MM:SS' format.
   */
  public getHrMinSec(timestamp: number): string {
    let hours = Math.floor(timestamp / 60 / 60);
    let minutes = Math.floor(timestamp / 60) - hours * 60;
    let seconds = Math.floor(timestamp % 60);
    return `${hours || '00'}:${minutes || '00'}:${seconds || '00'}`;
  }

  /**
   * Update the review count based on the driver result.
   * @param {any} driverResult - The result from the driver to check for reviews.
   */
  updateReviewCount(driverResult: any): void {
    // Check if driverResult and driverResult.driver_result are not null or undefined
    if (driverResult && driverResult.driver_result) {
      this.reviewCount = Object.values(driverResult.driver_result).some(
        (dr: any) =>
          dr.result &&
          Object.values(dr.result).some(
            (element: any) =>
              element.result &&
              element.result.some((res: any) => res.review_section)
          )
      );
    } else {
      // Handle the case where driverResult or driverResult.driver_result is null or undefined
      this.reviewCount = false;
    }
  }

  /**
   * Check if review orders are present in the driver result.
   * @param {any} driverResult - The driver result to check.
   * @returns {boolean} - True if there are review orders, false otherwise.
   */
  showReviewOrders(driverResult: any): boolean {
    return (
      driverResult?.result?.some(
        (order: any) => order.review_section === true
      ) || false
    );
  }

  /**
   * Check the length of the driver result in the report.
   * @param {any} report - The report containing driver results.
   * @returns {number} - The number of driver results.
   */
  checkDriverListLength(report: any): number {
    return (
      (report?.driver_result && Object.keys(report.driver_result).length) || 0
    );
  }
}
