import { Injectable, Injector } from '@angular/core';
import { FormattedData } from '../interfaces/common.interface';

@Injectable({
  providedIn: 'root',
})
export class MkOfficeService {
  public MODrpdwn: MarketOfficeZoneIds[] = [];
  public ids: number[] = [];
  public state: State[] = [];
  public permissionData: any;
  public mkOffice: MarketOfficeZoneIds[] = [];
  zone: any = {};
  zoneData: any = [];

  constructor() {}

  public getFormattedResponse = (response): FormattedData[] => {
    this.zoneData = [];
    this.zone = response;
    if (this.zone.result != undefined && this.zone.result != null) {
      const zoneRes = this.zone.result;
      for (let index in zoneRes) {
        if (zoneRes.hasOwnProperty(index)) {
          let zonesArr = [];
          let zoneDtlsArr = [];
          let marketofficeDtlsArr = [];
          let allmarketofficeDtls = [];
          let marketOffCity = [];
          let mkoffzoneArr = [];
          for (let mktIndx in zoneRes[index].market_office) {
            mkoffzoneArr = [];
            if (zoneRes[index]['market_office'].hasOwnProperty(mktIndx)) {
              let mkObj = {
                market_office_name:
                  zoneRes[index].market_office[mktIndx].market_office_name +
                  ' (' +
                  index +
                  ')',
                market_office_id:
                  index +
                  zoneRes[index].market_office[mktIndx].market_office_id,
                zones: [],
              };
              marketofficeDtlsArr = marketofficeDtlsArr.concat([mktIndx]);

              const cities = zoneRes[index]['market_office'][mktIndx].cities;
              for (let cityIndx in cities) {
                marketOffCity = marketOffCity.concat(cityIndx);
                if (cities.hasOwnProperty(cityIndx)) {
                  if (cities[cityIndx].zones) {
                    zonesArr = zonesArr.concat(
                      Object.keys(cities[cityIndx].zones)
                    );
                    for (let zn in cities[cityIndx].zones) {
                      if (cities[cityIndx].zones.hasOwnProperty(zn)) {
                        let znDtls: any = cities[cityIndx]['zones'][zn];
                        znDtls.state_code = index;
                        znDtls.city_name = cities[cityIndx].city_name;
                        znDtls.timezone =
                          zoneRes[index]['market_office'][mktIndx].timezone;
                        zoneDtlsArr = zoneDtlsArr.concat(znDtls);
                        mkoffzoneArr.push(znDtls);
                      }
                    }
                  }
                }
              }
              //mkObj.zones.push(zoneDtlsArr);
              mkObj.zones = mkoffzoneArr;
              allmarketofficeDtls.push(mkObj);
            }
          }

          this.zoneData.push({
            state_code: index,
            state: zoneRes[index].state_name,
            zone_id: zonesArr,
            zone_details: zoneDtlsArr,
            marketOfficeCities: marketOffCity,
            marketofficeDtls: marketofficeDtlsArr,
            allMarketOffice: allmarketofficeDtls,
          });
        }
      }
    }
    return this.zoneData;
  };

  /**
   * Retrieves formatted organization data with state and zone information from the provided response.
   * @param data The response data containing organization, state, and zone information.
   * @returns Formatted organization data with state and zone information.
   */
  public getFormattedOrganizationDataWithStatesAndZones = (data): any => {
    const organizations = [];

    // Iterate over each organization
    for (const orgId in data.result) {
      if (data.result.hasOwnProperty(orgId)) {
        const organization = data.result[orgId];
        const orgData = {
          org_id: organization.org_id,
          org_name: organization.org_name,
          checked: organization.checked,
          states: [], // Array to hold state data
        };

        // Iterate over each state within the organization
        for (const stateId in organization.states) {
          if (organization.states.hasOwnProperty(stateId)) {
            const state = organization.states[stateId];
            const stateData = {
              state_id: state.state_id,
              state_code: state.state_code, // Include state_code
              state_name: state.state_name,
              checked: state.checked,
              zones: [], // Array to hold zone data
            };

            // Iterate over each zone within the state
            for (const zoneId in state.zones) {
              if (state.zones.hasOwnProperty(zoneId)) {
                const zone = state.zones[zoneId];
                const zoneData = {
                  zone_id: zone.zone_id,
                  zone_name: zone.zone_name,
                  timezone: zone.timezone,
                  checked: zone.checked,
                };
                stateData.zones.push(zoneData); // Add zone data to the state
              }
            }

            orgData.states.push(stateData); // Add state data to the organization
          }
        }

        organizations.push(orgData);
      }
    }

    return organizations;
  };

  /*
   * Get States
   */
  public getStates = (params): State[] => {
    this.state = [];
    this.permissionData = params;
    params.map((m) => {
      this.state.push({ state_code: m.state_code, state: m.state });
    });
    return this.state.sort(this.compare);
  };

  /*
   * Function for sorting states
   */
  compare(a, b) {
    if (a.full_name < b.full_name) {
      return -1;
    }
    if (a.full_name > b.full_name) {
      return 1;
    }
    return 0;
  }
  /*
   * Get Market Office from State
   */
  public getMarketOffice = (state: string): MarketOffice[] => {
    let mkOfficeDetails: MarketOffice[] = [];
    this.mkOffice = this.permissionData.filter(
      (m) => m.state_code === state
    )[0]['allMarketOffice'];
    this.mkOffice.map((m) => {
      mkOfficeDetails.push({
        market_office_name: m.market_office_name,
        market_office_id: m.market_office_id,
      });
    });
    return mkOfficeDetails;
  };

  /*
   * Get Drivers Bt MO id
   */
  getZones(id: string) {
    let zonesIds: number[] = [];

    const mktOfc = this.mkOffice.filter((m) => m.market_office_id === id);
    mktOfc[0]['zones'].map((m) => {
      zonesIds.push(m.zone_id);
    });

    return zonesIds;
  }
}

// =====================================================
// INTERFACES
// =====================================================
export interface MarketOfficeZoneIds {
  market_office_id?: string;
  market_office_name?: string;
  zone_ids?: number[];
}
export interface State {
  state_code: string;
  state: string;
}

export interface MarketOffice {
  market_office_id: string;
  market_office_name: string;
}
