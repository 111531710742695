import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mat-select-search',
  templateUrl: './mat-select-search.component.html',
  styleUrls: ['./mat-select-search.component.scss'],
})
export class MatSelectSearchComponent {
  @Output() search = new EventEmitter<string>();

  onSearch(value: Event): void {
    this.search.emit(value.target['value']);
  }
}
