export const appSettings = {
  userCredentialsKey: 'apium-manager-user',
  rememberMe: 'remember-me',
  franchiseDtls: 'franchiseDtls',
  ftkn: 'ftkn',
  report_timezone: 'report_timezone',
  // --------------------------
  appTitle: 'Apium Manager',
  userDetails: 'userDetails',
  cryptoSecret: 'zsgj8o4ugor4c47red9kcwd0tikq237efcqumg4b4totipxhab',
  token: 'manager-token',
  firebaseToken: 'firebase-token',
  pageSize: 20,
};
