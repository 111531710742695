import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import apiEndPoints from '@apiEndPoints';
import { HttpService } from '@app/core/http';

@Injectable({
  providedIn: 'root',
})
export class DriverService {
  constructor(private _http: HttpService) {}

  /*
   * Method:driverListAPI
   * Reason: getting the driver list
   * Date:4thAugust, 2022
   */
  public driverListAPI(params) {
    return this._http.post(apiEndPoints.driver_list, params).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  /*
   * Method:driverListAPI
   * Reason: getting the driver list
   * Date:4thAugust, 2022
   */
  public oldDriverListAPI(params) {
    return this._http.post(apiEndPoints.old_driver_list, params).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  /*
   * Method:getDriverInfoAPI
   * Reason: getting the driver info for modal
   * Date:4thAugust, 2022
   */
  getDriverInfoAPI(params) {
    return this._http.post(apiEndPoints.driver_info, params).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
}
