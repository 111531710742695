import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  HostListener,
  ElementRef,
  SimpleChanges,
} from '@angular/core';

/**
 * MultiSelectSearchComponent is a custom multi-select dropdown component
 * that includes search functionality and allows selecting multiple options.
 */
@Component({
  selector: 'multi-select-search',
  templateUrl: './multi-select-search.component.html',
  styleUrls: ['./multi-select-search.component.scss'],
})
export class MultiSelectSearchComponent implements OnInit {
  /**
   * Input options for the dropdown. Each option is an object containing `id` and `name`.
   * @type {Array<{ id: number; name: string }>}
   */
  @Input() optionsData: { id: number; name: string }[] = [];

  /**
   * Placeholder text for the search input field.
   * @type {string}
   */
  @Input() placeholder: string = '';

  @Input() required: boolean = false;

  /**
   * Emits the selected options as an array of objects containing `id` and `name`.
   * @type {EventEmitter<Array<{ id: number; name: string }>>}
   */
  @Output() selectedOptions = new EventEmitter<
    { id: number; name: string }[]
  >();

  /**
   * Search term for filtering the dropdown options.
   * @type {string}
   */
  searchTerm = '';

  /**
   * Array of filtered options based on the search term.
   * @type {Array<{ id: number; name: string }>}
   */
  filteredOptions: { id: number; name: string }[] = [];

  /**
   * Array of selected options.
   * @type {Array<{ id: number; name: string }>}
   */
  selectedOptionsArray: { id: number; name: string }[] = [];

  /**
   * Tracks the visibility of the dropdown menu.
   * @type {boolean}
   */
  isOpen = false;

  /**
   * Stores the label for the selected options.
   * @type {string}
   */
  selectedLabel = '';
  @Input() formSubmitted: boolean = false;

  /**
   * Tracks whether there are selected options, used for validation.
   * @type {boolean}
   */
  hasSelected = true; // Initially true

  /**
   * @param {ElementRef} elRef - Reference to the component's DOM element.
   */
  constructor(private elRef: ElementRef) {}

  /**
   * Initializes the component by setting the filtered options to the full options data.
   */
  ngOnInit() {
    this.filteredOptions = this.optionsData;
    this.updateSelectedLabel();
  }

  /**
   * Reacts to changes in the input options data. Resets selection and filtering if the data is empty.
   * @param {SimpleChanges} changes - Object containing the changed input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['optionsData']?.currentValue?.length) {
      this.selectedOptionsArray = [];
      this.updateSelectedLabel();
    } else {
      // this.selectedOptionsArray = [];
      this.updateSelectedLabel();
      this.filteredOptions = [];
    }
  }

  /**
   * Handles the input change event to update the search term and filter the options.
   * @param {Event} event - The input event object.
   */
  onInputChange(event: any) {
    this.searchTerm = event.target.value;
    this.filterOptions();
    this.isOpen = true;
  }

  /**
   * Filters the options based on the current search term.
   */
  filterOptions() {
    this.filteredOptions = this.optionsData.filter((option) =>
      option.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  /**
   * Toggles the selection of a given option. Adds or removes it from the selected options array.
   * @param {{ id: number; name: string }} option - The option to toggle selection for.
   */
  toggleSelection(option: { id: number; name: string }) {
    const index = this.selectedOptionsArray.findIndex(
      (selectedOption) => selectedOption.id === option.id
    );

    if (index > -1) {
      this.selectedOptionsArray.splice(index, 1);
    } else {
      this.selectedOptionsArray.push(option);
    }

    this.selectedOptions.emit(this.selectedOptionsArray);
    this.updateSelectedLabel();
  }

  /**
   * Checks if a given option is selected.
   * @param {{ id: number; name: string }} option - The option to check.
   * @returns {boolean} - True if the option is selected, false otherwise.
   */
  isSelected(option: { id: number; name: string }): boolean {
    return this.selectedOptionsArray.some(
      (selectedOption) => selectedOption.id === option.id
    );
  }

  /**
   * Updates the selected label based on the selected options.
   */
  updateSelectedLabel() {
    const limit = 3; // Number of options to show before the "+N more"
    const count = this.selectedOptionsArray.length;

    if (count === 0) {
      this.selectedLabel = '';
    } else if (count <= limit) {
      this.selectedLabel = this.selectedOptionsArray
        .map((option) => option.name)
        .join(', ');
    } else {
      this.selectedLabel = `${this.selectedOptionsArray
        .slice(0, limit)
        .map((option) => option.name)
        .join(', ')} +${count - limit} more`;
    }
  }

  /**
   * Toggles the visibility of the dropdown menu.
   * @param {MouseEvent} event - The mouse event triggering the toggle.
   */
  toggleDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.filterOptions();
    this.isOpen = !this.isOpen;
  }

  /**
   * Toggles the selection of all currently filtered options.
   */
  toggleSelectAll() {
    if (this.isAllSelected()) {
      this.selectedOptionsArray = this.selectedOptionsArray.filter(
        (selectedOption) =>
          !this.filteredOptions.some(
            (option) => option.id === selectedOption.id
          )
      );
    } else {
      this.filteredOptions.forEach((option) => {
        if (!this.isSelected(option)) {
          this.selectedOptionsArray.push(option);
        }
      });
    }

    this.selectedOptions.emit(this.selectedOptionsArray);
    this.updateSelectedLabel();
  }

  /**
   * Checks if all currently filtered options are selected.
   * @returns {boolean} - True if all filtered options are selected, false otherwise.
   */
  isAllSelected(): boolean {
    return (
      this.filteredOptions.every((option) =>
        this.selectedOptionsArray.some(
          (selectedOption) => selectedOption.id === option.id
        )
      ) && this.filteredOptions.length > 0
    );
  }

  /**
   * Listens for click events outside the component to close the dropdown.
   * @param {MouseEvent} event - The mouse event triggering the listener.
   */
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.isOpen = false; // Close dropdown if click is outside
    }
  }

  /**
   * Checks if at least one option is selected only if the component is required.
   * @returns {boolean} - True if one or more options are selected and required is true, false otherwise.
   */
  hasSelectedOptions(): boolean {
    if (this.required && this.formSubmitted) {
      return this.selectedOptionsArray.length > 0;
    }
    return true;
  }
}
