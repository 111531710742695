import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskStatus',
})
export class TaskStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'tsk_proc_hld':
      case 'tsk_proc_mrchnt':
        return 'Scheduled';
      case 'tsk_proc_drv':
        return 'Processing';
      case 'tsk_cmp':
        return 'Completed';
      case 'tsk_cncling':
      case 'tsk_cncled':
        return 'Deleted';
      default:
        return '';
    }
  }
}
