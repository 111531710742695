<div class="manager_portal_login">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="text-center">
          <a href="#">
            <img src="assets/img/logo.png" height="100px" width="300px" />
          </a>
        </div>
      </div>
    </div>

    <div class="row mt-4" *ngIf="!!showForm">
      <div class="col">
        <div class="text-center">
          <h3>Sign Up To Manager Portal</h3>
        </div>
        <form [formGroup]="signUpForm" novalidate autocomplete="off">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input
                  [ngClass]="
                    signUpForm.get('first_name').hasError('required') &&
                    isSubmitted
                      ? 'red'
                      : ''
                  "
                  type="text"
                  class="form-control"
                  placeholder="First Name*"
                  formControlName="first_name"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Last Name*"
                  formControlName="last_name"
                  [ngClass]="
                    signUpForm.get('last_name').hasError('required') &&
                    isSubmitted
                      ? 'red'
                      : ''
                  "
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Email*"
                  formControlName="email"
                  autocomplete="off"
                  [readonly]="true"
                />
                <div
                  *ngIf="
                    signUpForm.get('email').hasError('required') && isSubmitted
                  "
                  class="text-danger"
                >
                  Email is required.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-3">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="country_code"
                      [ngClass]="
                        signUpForm.get('country_code').hasError('required') &&
                        isSubmitted
                          ? 'red'
                          : ''
                      "
                    />
                  </div>
                  <div class="col-9">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Phone*"
                      formControlName="phone"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="10 digit contact number"
                      [ngClass]="
                        signUpForm
                          .get('phone')
                          .hasError('invalidPhoneNumber') && isSubmitted
                          ? 'red'
                          : ''
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="input-group">
                  <input
                    type="{{ showPassword ? 'text' : 'password' }}"
                    class="form-control"
                    placeholder="Password*"
                    formControlName="password"
                    [ngClass]="
                      signUpForm.get('password').hasError('required') &&
                      isSubmitted
                        ? 'red'
                        : ''
                    "
                  />

                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i
                        *ngIf="showPassword"
                        (click)="togglePasswordVisibility()"
                        class="m-menu__link-icon flaticon-lock"
                      ></i>
                      <i
                        *ngIf="!showPassword"
                        (click)="togglePasswordVisibility()"
                        class="m-menu__link-icon flaticon-lock-1"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="input-group">
                  <input
                    type="{{ showConfirmPassword ? 'text' : 'password' }}"
                    class="form-control"
                    placeholder="Confirm Password*"
                    formControlName="confirmpassword"
                    [ngClass]="
                      signUpForm.get('confirmpassword').hasError('required') &&
                      isSubmitted
                        ? 'red'
                        : ''
                    "
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i
                        class="m-menu__link-icon flaticon-lock"
                        *ngIf="showConfirmPassword"
                        (click)="toggleConfirmPasswordVisibility()"
                      ></i>
                      <i
                        class="m-menu__link-icon flaticon-lock-1"
                        *ngIf="!showConfirmPassword"
                        (click)="toggleConfirmPasswordVisibility()"
                      ></i>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="
                    signUpForm
                      .get('confirmpassword')
                      .hasError('confirmedValidator')
                  "
                  class="text-danger"
                >
                  Passwords do not match.
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="text-center"></div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="text-center pb-4">
                <div *ngIf="signUpForm.invalid" class="text-danger">
                  Please fill out all required fields correctly.
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="text-center">
                <button
                  type="submit"
                  (click)="acceptSubAccSignup()"
                  class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row mt-4" *ngIf="!showForm">
      <div class="col">
        <div class="text-center">
          <h3 class="invalid-link">This link is no longer valid.</h3>
        </div>
      </div>
    </div>
  </div>
</div>
