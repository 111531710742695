import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnChanges {
  dropdownSettingsForZones: IDropdownSettings = {};
  @Output() selectOneEvent = new EventEmitter<any>();
  @Output() selectAllEvent = new EventEmitter<any>();
  @Output() deSelectOneEvent = new EventEmitter<any>();
  @Output() deSelectAllEvent = new EventEmitter<any>();

  @Input() data;
  @Input() idField;
  @Input() textField;
  @Input() parent: FormGroup;
  @Input() placeholder;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    this.dropdownSettingsForZones = {
      singleSelection: false,
      idField: this.idField,
      textField: this.textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      defaultOpen: false,
    };
  }

  /*
   * Select One
   */
  public selectOne = (event): void => {
    return this.selectOneEvent.emit(event);
  };
  /*
   * Select All
   */
  public selectAll = (event): void => {
    return this.selectAllEvent.emit(event);
  };
  /*
   * Deselect One
   */
  public deselectOne = (event): void => {
    return this.deSelectOneEvent.emit(event);
  };
  /*
   * Deselect All
   */
  public onDeSelectAll = (event): void => {
    return this.deSelectAllEvent.emit(event);
  };
}
