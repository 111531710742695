import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { dateFormat, futureOrderDateFormat } from '@shared/utilities';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  public form: FormGroup;

  public params: Params = {
    start_date: '',
    end_date: '',
  };

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        sDate: ['', Validators.required],
        eDate: ['', Validators.required],
      },
      { validator: dateRangeValidator() } // Add the custom validator here
    );
  }

  public dateRange = (): Params => {
    this.form.value.sDate = dateFormat(this.form.value.sDate);
    this.form.value.eDate = dateFormat(this.form.value.eDate);

    (this.params.start_date = this.form.value.sDate),
      (this.params.end_date = this.form.value.eDate);

    return this.params || null;
  };
}
export interface Params {
  start_date: string;
  end_date: string;
}
function dateRangeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const startDate = control.get('sDate').value;
    const endDate = control.get('eDate').value;

    if (startDate && endDate) {
      const sixMonthsFromStart = new Date(startDate);
      sixMonthsFromStart.setMonth(sixMonthsFromStart.getMonth() + 36);

      if (endDate > sixMonthsFromStart) {
        return { dateRangeExceeded: true };
      }
    }

    return null;
  };
}
