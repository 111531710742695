import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class CustomValidators {
  constructor() {}
}

export function ageRangeValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  if (
    control.value !== undefined &&
    (isNaN(control.value) || control.value < 18 || control.value > 45)
  ) {
    return { ageRange: true };
  }
  return null;
}

export function emptyField(
  control: AbstractControl
): { [key: string]: boolean } | null {
  if (
    control.value !== undefined ||
    control.value === null ||
    control.value === ''
  ) {
    return { blankField: true };
  }

  return null;
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (
      matchingControl.errors &&
      !matchingControl.errors['confirmedValidator']
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
export function validatePhoneNumber(control) {
  const phoneNumber = control.value;

  if (phoneNumber && phoneNumber.length === 10 && /^\d+$/.test(phoneNumber)) {
    return null; // Valid
  } else {
    return { invalidPhoneNumber: true }; // Invalid
  }
}
