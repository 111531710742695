import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'note',
})
export class NotePipe implements PipeTransform {
  transform(
    items: { classification: string; barcode: string }[] | null
  ): string {
    if (!items) {
      return 'No data available'; // Return a default message or handle as needed
    }

    // Initialize an object to hold counts for each classification
    const classificationMap: { [key: string]: number } = {
      regular: 0,
      cold: 0,
      others: 0,
    };

    // Populate the classificationMap with counts
    items.forEach((item) => {
      const { classification } = item;
      // Increment the count based on the classification
      if (classificationMap.hasOwnProperty(classification.toLowerCase())) {
        classificationMap[classification.toLowerCase()] += 1;
      } else {
        console.log('Unknown classification:', classification);
      }
    });

    // Generate notes from the classificationMap
    let notes = '';
    for (const [key, value] of Object.entries(classificationMap)) {
      switch (key.toLowerCase()) {
        case 'regular':
          notes = `${notes}\n${value} cardboard box(es)`;
          break;
        case 'cold':
          notes = `${notes}\n${value} cold pack(s)`;
          break;
        case 'others':
          notes = `${notes}\n${value} loose items`;
          break;
        default:
          console.log('No classification match!');
      }
    }

    return notes.trim() || 'No data available'; // Return a default message if notes is empty
  }
}
