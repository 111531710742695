import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zoneFilter',
})
export class ZoneFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((item) => {
      // Modify this condition based on your filtering criteria
      return item.zone_name.toLowerCase().includes(searchText);
    });
  }
}
