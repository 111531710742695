<div class="container">
  <form class="future_fit" [formGroup]="form">
    <!-- First Row -->
    <div class="row">
      <div class="col-md-4 pb-3">
        <mat-form-field appearance="outline" class="custom-form-field">
          <mat-label>Select an Organization</mat-label>
          <mat-select
            formControlName="organization"
            (selectionChange)="onOrganizationSelect($event.value)"
          >
            <mat-option
              *ngFor="let organization of organizations"
              [value]="organization.id"
            >
              {{ organization.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4 pb-3">
        <mat-form-field appearance="outline" class="custom-form-field">
          <mat-label>Select a State</mat-label>
          <mat-select
            formControlName="state"
            (selectionChange)="onStateSelect($event.value)"
          >
            <mat-option *ngFor="let state of states" [value]="state">
              {{ state.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <multi-select-search
          [placeholder]="'Select zones'"
          [optionsData]="zones"
          (selectedOptions)="onSelectedOptions($event, 'zone')"
          [required]="true"
          [formSubmitted]="formSubmitted"
        ></multi-select-search>
      </div>
    </div>

    <!-- Second Row -->
    <div class="row mt-3">
      <div class="col-md-4 pb-3" *ngIf="showDriversSelection">
        <multi-select-search
          [placeholder]="'Select drivers'"
          [optionsData]="drivers"
          (selectedOptions)="onSelectedOptions($event, 'driver')"
          [required]="true"
          [formSubmitted]="formSubmitted"
        ></multi-select-search>
      </div>

      <div class="col-md-4 pb-3">
        <mat-form-field appearance="outline" class="custom-form-field">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="startDatePicker"
            formControlName="startDate"
            placeholder="Choose a start date"
            [min]="minDate"
            [max]="maxDate"
            class="text-ind"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="custom-form-field">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="endDatePicker"
            formControlName="endDate"
            placeholder="Choose an end date"
            [min]="minDate"
            [max]="maxDate"
            class="text-ind"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <!-- Third Row with Button -->
    <div class="row mt-3">
      <div class="col-md-12 text-left">
        <button class="btn blue-color" (click)="onSubmit()">Search</button>
      </div>
    </div>
  </form>
</div>

