import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NetworkAwarePreloadModulesStrategy } from '@core/strategies';
import { ApiumAuthLayoutComponent } from '@core/layouts/auth';
import { ApiumManagerLayoutComponent } from './core/layouts/manager/apium-manager-layout/apium-manager-layout.component';
import { routes as routesList } from '@routes';
import { AuthGuard } from './core/guards';
import { NotFoundComponent } from './_components/not-found/not-found.component';
import { SignUpComponent } from './_components/sign-up/sign-up.component';
import { InviteResolveService } from './shared/services/invite-resolve.service';

const appRoutes: Routes = [
  {
    path: '',
    component: ApiumAuthLayoutComponent,
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: ApiumManagerLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: routesList.DASHBOARD,
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: routesList.PROFILE,
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: routesList.TASKS,
        loadChildren: () =>
          import('./pages/task/task.module').then((m) => m.TaskModule),
      },
      {
        path: routesList.DRIVER,
        loadChildren: () =>
          import('./pages/driver/driver.module').then((m) => m.DriverModule),
      },
      {
        path: routesList.REPORTS,
        loadChildren: () =>
          import('./pages/reports/reports.module').then((m) => m.ReportsModule),
      },
    ],
  },
  {
    path: 'invite/:code',
    component: SignUpComponent,
    resolve: {
      inviteInfo: InviteResolveService,
    },
  },
  {
    path: '**',
    redirectTo: '404',
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top',
      preloadingStrategy: NetworkAwarePreloadModulesStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
