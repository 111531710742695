import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { routes } from '@app/configs';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication';
import { LoggerService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class SecureOuterGuard implements CanActivate {
  private routes: typeof routes = routes;

  constructor(
    private router: Router,
    private _logger: LoggerService,
    private _authService: AuthenticationService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAuthenticated = this._authService.isLoggedIn();

    if (isAuthenticated) {
      const url = route.url[0].toString();
      switch (url) {
        case routes.LOGIN:
          this.router.navigate([`/${routes.DASHBOARD}`]);
          return false;
        case routes.FORGOT_PASSWORD:
          this.router.navigate([`/${routes.DASHBOARD}`]);
          return false;
        case routes.RESET_PASSWORD:
          this.router.navigate([`/${routes.DASHBOARD}`]);
          return false;
        default:
          return true;
      }
    }
    // not logged in so redirect to login page with the return url
    return true;
  }
}
