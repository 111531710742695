import { Injectable, OnDestroy } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import apiEndPointsConfig from '@apiEndPoints';
import { HttpService } from '@app/core/http';
import { Subscription, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class InviteResolveService implements Resolve<any>, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    private httpService: HttpService,
    private _snack: SnackBarService
  ) {}

  /**
   * Resolves invitee details before navigating to the route.
   * @param {ActivatedRouteSnapshot} route - The activated route snapshot.
   * @returns {Observable<any>} - An observable with the invitee details, or a fallback observable in case of error.
   */
  resolve(route: ActivatedRouteSnapshot) {
    const verificationCode = route.paramMap.get('code');

    const inviteeDetails$ = this.httpService
      .get(`${apiEndPointsConfig.invitations}/${verificationCode}`, {
        useUrlPrefix: true,
      })
      .pipe(
        catchError((error) => {
          this._snack.notifyError(error.message);
          // Return a fallback value or navigate to an error page
          return of(null); // Return an observable with a fallback value
        })
      );

    const subscription = inviteeDetails$.subscribe();
    this.subscriptions.push(subscription);

    return inviteeDetails$;
  }

  /**
   * Unsubscribes from all active subscriptions to prevent memory leaks.
   */
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
