import { Injectable } from '@angular/core';
export interface ICustomWindow extends Window {
  spotifyCallback: any;
  opener: any;
}

@Injectable()
export class WindowRefService {
  /**
   * *method to get window
   *
   * @date 28 June 2022
   * @developer Apium
   */
  get nativeWindow(): ICustomWindow {
    return getWindow();
  }
}

/**
 * *Getting window referance from window object
 *
 * @date 28 June 2022
 * @developer Apium
 */
function getWindow(): any {
  return window;
}
