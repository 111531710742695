import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import apiEndPointsConfig from '@app/configs/api-end-points.config';
import { Errors } from '@app/configs/errors.configs';
import { HttpService } from '@app/core/http';
import { LoaderService } from '@app/shared/modules/loader';
import { SnackBarService } from '@app/shared/services/snack-bar.service';
import { MustMatch } from '@app/shared/validators';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  /**
   * Form group for resetting the password.
   * @type {FormGroup}
   */
  resetPasswordForm: FormGroup;

  /**
   * Error messages definition.
   * @type {typeof Errors}
   */
  errors: typeof Errors = Errors;

  /**
   * Flag to track form submission.
   * @type {boolean}
   */
  submitted: boolean = false;

  /**
   * User ID extracted from the route parameters.
   * @type {number}
   */
  userId: number = 0;

  /**
   * Subscription array for managing and unsubscribing from observables.
   * @type {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * Constructor to inject necessary services.
   * @param {UntypedFormBuilder} formBuilder - Service to build forms.
   * @param {LoaderService} loaderService - Service to show/hide loaders.
   * @param {SnackBarService} snackBarService - Service to show snack bar notifications.
   * @param {HttpService} http - Service to handle HTTP requests.
   * @param {Router} router - Service to handle routing.
   * @param {ActivatedRoute} activatedRoute - Service to handle route parameters.
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private snackBarService: SnackBarService,
    private http: HttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.userId = this.activatedRoute.snapshot.params['resId'] || 0;
  }

  /**
   * Initializes the reset password form with validators.
   */
  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ['', Validators.required],
        confirm_password: ['', Validators.required],
      },
      {
        validators: MustMatch('password', 'confirm_password'),
      }
    );
  }

  /**
   * Getter method to access form controls in the template.
   * @returns {FormGroup['controls']} The form controls.
   */
  get formControls() {
    return this.resetPasswordForm.controls;
  }

  /**
   * Resets the user's password by making an API call.
   */
  resetPassword(): void {
    this.submitted = true;

    if (this.resetPasswordForm.invalid) return;

    this.loaderService.showLoader();

    const sub = this.http
      .post(
        apiEndPointsConfig.resetpassword,
        {
          hashId: this.userId,
          password: this.resetPasswordForm.value.password,
        },
        { useUrlPrefix: true }
      )
      .subscribe({
        next: (response: any) => {
          this.snackBarService.notifySuccess(response.message);
          this.router.navigate(['login']);
        },
        error: (err: Error) => {
          this.snackBarService.notifyError(err.message);
          this.loaderService.hideLoader();
        },
        complete: () => {
          this.loaderService.hideLoader();
        },
      });

    this.subscriptions.push(sub);
  }

  /**
   * Unsubscribes from all subscriptions to prevent memory leaks.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
