import { Injectable } from '@angular/core';
import { appSettings } from '@app/configs';
import { map, filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

const APP_TITLE = appSettings.appTitle;
const SEPARATOR = ' | ';

@Injectable()
export class TitleService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {}

  /**
   * *Setting route name first letetr uppercase
   *
   * @param text - route name
   * @returns string
   * @date 28 June 2022
   * @developer Apium
   */
  static ucFirst(text: string): string {
    if (!text) {
      return text;
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  /**
   * *Dynamically setting page title
   *
   * @date 28 June 2022
   * @developer Apium
   */
  init() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        map((route) => route.snapshot),
        map((snapshot) => {
          /**
           * *if have a title in routes
           */
          if (snapshot.data['title']) {
            if (snapshot.paramMap.get('id') !== null) {
              return (
                snapshot.data['title'] + SEPARATOR + snapshot.paramMap.get('id')
              );
            }
            return snapshot.data['title'];
          } else {
            /**
             * *If not, we do a little magic on the url to create an approximation
             */
            return this.router.url.split('/').reduce((acc, frag) => {
              if (acc && frag) {
                acc += SEPARATOR;
              }
              return acc + TitleService.ucFirst(frag);
            });
          }
        })
      )
      .subscribe((pathString) => {
        if (pathString !== '') {
          this.titleService.setTitle(`${APP_TITLE} ${SEPARATOR} ${pathString}`);
        }
      });
  }
}
