import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeZoneConverted2',
})
export class TimeZoneConverted2Pipe implements PipeTransform {
  transform(time: any, timezone: string): unknown {
    if (time === 0 || time === '') return 'No Date';
    else return moment(time).tz(timezone).format('MM/DD/YYYY hh:mm A');
  }
}
