import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { routes } from '@app/configs';
import { ResponsiveMenuService } from '@app/core/services/responsive-menu.service';

@Component({
  selector: 'apium-sidebar-layout',
  templateUrl: './apium-sidebar-layout.component.html',
  styleUrls: ['./apium-sidebar-layout.component.scss'],
})
export class ApiumManagerSidebarLayoutComponent implements OnInit {
  public currentYear: number = new Date().getFullYear();
  public routes: typeof routes = routes;
  overlayLeft: boolean = true;

  // Sub menus
  public reportSubMenu: boolean = false;

  constructor(private router: Router, private rMenu: ResponsiveMenuService) {
    this.rMenu.currentMessage.subscribe((message) => {
      if (message) {
        this.mobileMenuOpen();
      }
      this.overlayLeft = !this.overlayLeft;
    });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        // this.currentRoute = event.url;
        // this.menu(event.url);
        if (event.url) {
          this.closeMenu();
        }

        if (event.url.indexOf('/reports/') !== -1) {
          this.reportSubMenu = true;
        } else {
          this.reportSubMenu = false;
        }
      }
    });
  }

  ngOnInit(): void {}

  /*
   * Method:mobileMenuOpen
   * Reason:To open repsonsive menu
   * Date:11th August,2022
   */
  mobileMenuOpen() {
    let classSelect = document.querySelectorAll(
      '.m-grid__item.m-aside-left.m-aside-left--skin-light'
    );
    let numberclass = document.querySelectorAll(
      '.m-grid__item.m-aside-left.m-aside-left--skin-light'
    ).length;
    let i: any;
    for (i = 0; i < numberclass; i++) {
      classSelect[i].classList.toggle('m-aside-left--on');
    }
  }

  /*
   * Method:closeMenu
   * Reason:To close repsonsive menu
   * Date:11th August,2022
   */
  closeMenu() {
    let classSelect = document.querySelectorAll(
      '.m-grid__item.m-aside-left.m-aside-left--skin-light'
    );
    let numberclass = document.querySelectorAll(
      '.m-grid__item.m-aside-left.m-aside-left--skin-light'
    ).length;
    let i: any;
    for (i = 0; i < numberclass; i++) {
      classSelect[i].classList.remove('m-aside-left--on');
    }
    this.overlayLeft = false;
  }
}
