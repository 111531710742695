<div class="m-subheader">
  <div class="d-flex align-items-center pb-4">
    <div class="mr-auto">
      <h3>California Reports</h3>
      <p>{{ name }}</p>
    </div>
    <div></div>
  </div>
</div>

<div class="m-content">
  <form
    autocomplete="off"
    novalidate
    [formGroup]="reportForm"
    (ngSubmit)="search()"
  >
    <!-- Search Panel Row 1 -->
    <div class="row search-panel search-panel-normal">
      <!-- Column 1: Select Organization -->
      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Select Organization</mat-label>
          <mat-select
            formControlName="organizations"
            (selectionChange)="onOrganizationSelectionChange($event.value)"
          >
            <mat-option
              *ngFor="let organization of organizations"
              [value]="organization.org_id"
            >
              {{ organization.org_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Column 2: Select State -->
      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Select State</mat-label>
          <mat-select
            formControlName="state"
            (selectionChange)="onStateSelectionChange($event.value)"
          >
            <mat-option *ngFor="let state of states" [value]="state.state_id">
              {{ state.state_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Column 3: Select Zone -->
      <div class="col-md-4 col-sm-12">
        <ng-multiselect-dropdown
          [placeholder]="'Select Zones'"
          formControlName="zone"
          [settings]="dropdownSettingsForZones"
          [data]="selectedState?.zones"
          (onSelect)="selectOneZone($event)"
          (onDeSelect)="deselectOneZone($event)"
          (onSelectAll)="selectAllZones($event)"
          (onDeSelectAll)="onDeSelectAllZones($event)"
        >
        </ng-multiselect-dropdown>
      </div>
      <!-- Column 1: Select Driver -->
      <div class="col-md-4 col-sm-12">
        <ng-multiselect-dropdown
          [placeholder]="'Select Drivers'"
          formControlName="driver"
          [settings]="dropdownSettingsForDrivers"
          [data]="driverList"
          (onSelect)="selectOneDriver($event)"
          (onDeSelect)="deselectOneDriver($event)"
          (onSelectAll)="selectAllDrivers($event)"
          (onDeSelectAll)="deselectAllDrivers()"
        >
        </ng-multiselect-dropdown>
      </div>

      <!-- Column 2: Start Date -->
      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="sdate"
            formControlName="st_dt"
            [min]="minDateSt"
            [max]="maxDateSt"
            (focus)="sdate.open()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="sdate"
          ></mat-datepicker-toggle>
          <mat-datepicker #sdate></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Column 3: End Date -->
      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="edate"
            formControlName="en_dt"
            [min]="minDateEn"
            [max]="maxDateEn"
            (focus)="edate.open()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="edate"
          ></mat-datepicker-toggle>
          <mat-datepicker #edate></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Submit Button -->
      <div class="col-md-4 col-sm-12">
        <button
          type="button"
          class="btn blue-color mt-2"
          (click)="search()"
          [disabled]="!reportForm.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>
</div>
<california-reports></california-reports>
