import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeZoneConvert',
})
export class TimeZoneConvertPipe implements PipeTransform {
  transform(value: number | string, timezone: string = 'America/Los_Angeles'): string {
    // Check if the value is 0 or invalid
    if (value === 0 || !value) {
      return 'Invalid Date';
    }

    // Parse the time assuming it's a valid date string
    const dateTime = new Date(value);

    // Check if the dateTime is valid
    if (isNaN(dateTime.getTime())) {
      return 'Invalid Date';
    }

    // If valid, return the original value
    return value.toString();
  }
}
