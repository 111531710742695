import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'formattedTime',
})
export class FormattedTimePipe implements PipeTransform {
  transform(itemTime: number, itemTimezone?: string): string {
    if (!itemTime || !itemTimezone) {
      return '';
    }

    return moment.unix(itemTime).tz(itemTimezone).format('hh:mm A (MM/DD)');
  }
}
