import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskBatch',
})
export class TaskBatchPipe implements PipeTransform {
  transform(value: string): string {
    let suffix = '';

    switch(value) {
      case 'tsk_proc_hld':
      case 'tsk_proc_mrchnt':
        suffix = 'btn_Scheduled';
        break;
      case 'tsk_proc_drv':
        suffix = 'btn_Processing';
        break;
      case 'tsk_cmp':
        suffix = 'btn_Completed';
        break;
      case 'tsk_cncling':
      case 'tsk_cncled':
        suffix = 'btn_Deleted';
        break;
    }

    return suffix;
  }
}
