import { Component } from '@angular/core';
import { appSettings } from '@configs/app-settings.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = appSettings.appTitle;

  constructor() {
    // throw new Error('Syntry Error');
  }
}
