import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'apium-footer-layout',
  templateUrl: './apium-footer-layout.component.html',
  styleUrls: ['./apium-footer-layout.component.scss'],
})
export class ApiumManagerFooterLayoutComponent implements OnInit {
  public currentYear: number = new Date().getFullYear();

  constructor() {}

  ngOnInit(): void {}
}
