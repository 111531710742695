import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
/**
 * SuccessInterceptor class that implements HttpInterceptor to log successful HTTP responses.
 */
export class SuccessInterceptor implements HttpInterceptor {
  /**
   * Intercepts HTTP requests and logs successful responses.
   *
   * @param req - The outgoing HTTP request
   * @param next - The next interceptor in the chain
   * @returns An Observable of the HTTP event, logging responses on success
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.ok) {
          // Log response details on success
          // console.log('SuccessInterceptor - Response received:');
          // console.log('Status:', event.status);
          // console.log('Status Text:', event.statusText);
          // console.log('Headers:', event.headers);
          // console.log('Body:', event.body);

          // Additional logic such as dispatching actions to update state can be added here
        }
      })
    );
  }
}
