<!-- END: Subheader -->

<div class="m-content">
  <div class="m-portlet m-portlet--mobile m-0">
    <div class="m-portlet__body reports-main-con">
      <div class="row ca_report search-panel search-panel-normal">
        <!-- some content -->
      </div>

      <h6>Review orders</h6>
      <div class="w-50 mt-3 over_height">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Order number</th>
              <th scope="col">Mileage</th>
              <th scope="col">Engaged time</th>
            </tr>
          </thead>
          <tbody>
            <tr class="alert alert-warning" role="alert">
              <td
                colspan="3"
                class="no_record"
                role="alert"
                *ngIf="reviewCount == false"
              >
                No reviews to be done
              </td>
            </tr>
            <ng-container *ngIf="checkDriverListLength(report) > 0">
              <ng-container
                *ngFor="
                  let dateWiseDel of objectValues(report['driver_result'])
                "
              >
                <ng-container
                  *ngFor="let drvRslt of objectValues(dateWiseDel['result'])"
                >
                  <tr *ngIf="showReviewOrders(drvRslt)">
                    <td colspan="3">
                      <b>{{ drvRslt["date_delivery_date"] }}</b>
                      <span class="ml-2 text-warning">
                        Driver Hours:
                        {{ drvRslt["date_hourly"]["hours"] }}
                        hours</span
                      >
                    </td>
                  </tr>
                  <tr *ngFor="let order of drvRslt['result']">
                    <ng-container *ngIf="order['review_section']">
                      <td scope="row">
                        {{ order["order_number"] }}
                      </td>
                      <td>
                        {{ order.mileage || 0 | number : "1.2-2" }}
                        miles
                      </td>
                      <td>
                        {{ order.engaged_time || 0 | number : "1.2-2" }} min
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <mat-grid-list
          cols="4"
          rowHeight="4:1"
          *ngIf="checkDriverListLength(report) > 0"
        >
          <mat-grid-tile>
            Deliveries:
            {{ (report && report["final_order_count"]) || 0 }}</mat-grid-tile
          >
          <mat-grid-tile>
            Mileage Reimbursment:
            {{
              (report && report["final_mileage_pay"]) || 0
                | currency : "USD" : "symbol" : "1.2-2"
            }}
            (Miles:
            {{
              (report && report["final_mileage"]) || 0 | number : "1.2-2"
            }})</mat-grid-tile
          >
          <mat-grid-tile>
            Tips:
            {{
              (report && report["final_tips"]) || 0
                | currency : "USD" : "symbol" : "1.2-2"
            }}</mat-grid-tile
          >
          <mat-grid-tile>
            Hourly Incentive:
            {{
              report.final_actual_pay - report.final_total_pay || 0
                | currency : "USD" : "symbol" : "1.2-2"
            }}</mat-grid-tile
          >
          <mat-grid-tile>
            Drivers:
            {{ objectKeys(report.driver_result).length || 0 }}</mat-grid-tile
          >
          <mat-grid-tile>
            Engaged Time Reimbursment:
            {{
              report.final_engaged_time_pay || 0
                | currency : "USD" : "symbol" : "1.2-2"
            }}
            (Total time:
            {{ getHrMinSec(report.final_engaged_time * 60) || 0 }}
            )
          </mat-grid-tile>
          <mat-grid-tile>
            Incentive:
            {{
              report["final_incentive"] || 0
                | currency : "USD" : "symbol" : "1.2-2"
            }}
          </mat-grid-tile>
          <mat-grid-tile>
            Total:
            {{
              report["final_actual_pay"] || 0
                | currency : "USD" : "symbol" : "1.2-2"
            }}
          </mat-grid-tile>
        </mat-grid-list>
      </div>

      <div
        class="card shadow mt-3 p-3"
        *ngIf="checkDriverListLength(report) == 0"
      >
        <div class="no_record" role="alert">No records found</div>
      </div>

      <div class="card shadow mt-3" *ngIf="checkDriverListLength(report) > 0">
        <div class="p-3 text-right">
          <p class="pb-1"></p>
          <span class="font-weight-bold"
            >Total Driver Pay:
            {{
              report["final_actual_pay"] || 0
                | currency : "USD" : "symbol" : "1.2-2"
            }}</span
          >
        </div>

        <div class="card-body pt-0">
          <!-- driver card  -->
          <ng-container
            *ngFor="
              let drivers of objectValues(report['driver_result']);
              let i = index
            "
          >
            <a id="driver{{ i }}" class="driver-section"></a>
            <div class="card mb-3 scroll_v">
              <div class="card">
                <div class="p-3">
                  <p class="text-left mb-1 dr-name">
                    <!-- <i class="icofont-steering"></i>-->
                    {{ drivers["driver_name"] }}
                  </p>
                  <p class="font-weight-bold">
                    Total Order: {{ drivers["drv_order_count"] }}
                  </p>
                  <p class="font-weight-bold">
                    Driver Mileage:
                    {{ drivers["drv_mileage"] || 0 | number : "1.2-2" }} miles
                  </p>
                  <p class="font-weight-bold">
                    Driver Mileage Pay:
                    {{
                      drivers["drv_mileage_pay"] || 0
                        | currency:'USD':'symbol':'1.2-2'
                    }}
                  </p>
                  <p class="font-weight-bold">
                    Driver Engaged Time:
                    {{ drivers["drv_engaged_time"] || 0 | number : "1.2-2" }}
                    min
                  </p>
                  <p class="font-weight-bold">
                    Driver Engaged Time Pay:
                    {{
                      drivers["drv_engaged_time_pay"] || 0
                        | currency : "USD" : "symbol" : "1.2-2"
                    }}
                  </p>
                  <p class="font-weight-bold">
                    Driver Hours:
                    {{
                      (drivers["drv_hourly"] && drivers["drv_hourly"].hours) ||
                        0
                    }}
                    Hours
                  </p>
                  <p class="font-weight-bold">
                    Base Pay Rate:
                    {{
                      (drivers["drv_hourly"] &&
                        drivers["drv_hourly"].base_pay_rate) ||
                        0 | currency : "USD" : "symbol" : "1.2-2"
                    }}
                  </p>
                </div>
                <div>
                  <table class="table">
                    <thead class="thead-dark">
                      <tr>
                        <th>Order number</th>
                        <th>Mileage</th>
                        <th>Mileage pay</th>
                        <th>Engaged time</th>
                        <th><abbr title="Engagement Time Offset">ETO</abbr></th>
                        <th>Engaged time pay</th>
                        <th>Tips</th>
                        <th>Incentive</th>
                        <th>Total pay</th>
                        <th>Hourly incentive</th>
                        <th>Driver pay</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container
                        *ngFor="
                          let dateWiseDel of objectValues(drivers['result'])
                        "
                      >
                        <tr>
                          <td colspan="11">
                            <b>{{ dateWiseDel["date_delivery_date"] }}</b>
                            <span class="ml-2 text-warning"
                              >Driver Hours:
                              {{
                                dateWiseDel["date_hourly"].hours || "0:00:00"
                              }}
                              hours</span
                            >
                          </td>
                        </tr>
                        <tr
                          *ngFor="let order of dateWiseDel['result']"
                          [ngClass]="
                            order && order.red
                              ? 'light-red'
                              : order.engaged_time_editable == 'true'
                              ? ''
                              : 'yellow_row'
                          "
                        >
                          <td>
                            <i
                              class="m-menu__link-icon flaticon-plus ph-ord"
                              *ngIf="order.tsk_type == 'pharmacy'"
                            ></i>
                            <span class="clickable">{{
                              order.order_number
                            }}</span>
                          </td>
                          <td>
                            <span
                              >{{
                                order.mileage || 0 | number : "1.2-2"
                              }}
                              miles</span
                            >
                          </td>
                          <td>
                            <span>{{ order.mileage_pay || 0 | currency }}</span>
                          </td>
                          <td>
                            <span>
                              {{ order.engaged_time || 0 | number : "1.2-2" }}
                              min</span
                            >
                          </td>
                          <td>
                            <span>{{ order.engagement_time_offset }}</span>
                          </td>
                          <td>
                            <span>{{
                              order.engaged_time_pay || 0 | currency
                            }}</span>
                          </td>
                          <td>
                            <span>{{ order.tip || 0 | currency }}</span>
                          </td>
                          <td>
                            <span>{{ order.incentive || 0 | currency }}</span>
                          </td>
                          <td>{{ order.total_pay || 0 | currency }}</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="10"><b>Additional Incentive</b></td>
                          <td>
                            <span>
                              {{
                                dateWiseDel["date_additional_incentive"] || 0
                                  | currency : "USD" : "symbol" : "1.2-2"
                              }}</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <th>Total</th>
                          <td>
                            {{
                              dateWiseDel["date_mileage"] || 0
                                | number : "1.2-2"
                            }}
                            miles
                          </td>
                          <td>
                            {{
                              dateWiseDel["date_mileage_pay"] || 0 | currency
                            }}
                          </td>
                          <td>
                            {{
                              dateWiseDel["date_engaged_time"] || 0
                                | number : "1.2-2"
                            }}
                            min
                          </td>
                          <td></td>
                          <td>
                            {{
                              dateWiseDel["date_engaged_time_pay"] || 0
                                | currency
                            }}
                          </td>
                          <td>
                            {{ dateWiseDel["date_tips"] || 0 | currency }}
                          </td>
                          <td>
                            {{ dateWiseDel["date_incentive"] || 0 | currency }}
                          </td>
                          <td>
                            {{ dateWiseDel["date_total_pay"] || 0 | currency }}
                          </td>
                          <td>
                            {{
                              (dateWiseDel["date_actual_pay"] -
                                dateWiseDel["date_total_pay"] || 0) -
                                (dateWiseDel["date_additional_incentive"] || 0)
                                | currency
                            }}
                          </td>
                          <td>
                            {{ dateWiseDel["date_actual_pay"] || 0 | currency }}
                          </td>
                        </tr>
                      </ng-container>
                      <tr>
                        <th>All Total</th>
                        <th>
                          {{ drivers["drv_mileage"] || 0 | number : "1.2-2" }}
                          miles
                        </th>
                        <th>
                          {{ drivers["drv_mileage_pay"] || 0 | currency }}
                        </th>
                        <th>
                          {{
                            drivers["drv_engaged_time"] || 0 | number : "1.2-2"
                          }}
                          min
                        </th>
                        <th></th>
                        <th>
                          {{ drivers["drv_engaged_time_pay"] || 0 | currency }}
                        </th>
                        <th>{{ drivers["drv_tips"] || 0 | currency }}</th>
                        <th>{{ drivers["drv_incentive"] || 0 | currency }}</th>
                        <th>{{ drivers["drv_total_pay"] || 0 | currency }}</th>
                        <th>
                          {{
                            drivers["drv_actual_pay"] -
                              drivers["drv_total_pay"] -
                              drivers["drv_additional_incentive"] || 0
                              | currency
                          }}
                        </th>
                        <th>{{ drivers["drv_actual_pay"] || 0 | currency }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-muted">
                  <table class="table table-dark">
                    <thead>
                      <tr>
                        <th scope="row" class="text-center">
                          Engaged time
                          <span style="display: block" class="text-center"
                            >(Minutes)</span
                          >
                        </th>
                        <th scope="row" class="text-center">
                          Quarter 1
                          <span style="display: block">Jan 1 - Mar 31th</span>
                        </th>

                        <th scope="row" class="text-center">
                          Quarter 2<span
                            style="display: block"
                            class="text-center"
                          >
                            Apr 1 - Jun 30</span
                          >
                        </th>
                        <th scope="row" class="text-center">
                          Quarter 3
                          <span style="display: block" class="text-center"
                            >Jul 1 - Sep 30</span
                          >
                        </th>
                        <th scope="row" class="text-center">
                          Quarter 4
                          <span style="display: block" class="text-center">
                            Oct 1 - Dec 31</span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let qData of drivers['drv_quaterly'] | keyvalue"
                      >
                        <th scope="row" class="text-center">{{ qData.key }}</th>
                        <td class="text-center">
                          {{
                            objectValues(qData.value)[0]["engaged_time"] || 0
                              | number : "1.2-2"
                          }}
                        </td>
                        <td class="text-center">
                          {{
                            objectValues(qData.value)[1]["engaged_time"] || 0
                              | number : "1.2-2"
                          }}
                        </td>
                        <td class="text-center">
                          {{
                            objectValues(qData.value)[2]["engaged_time"] || 0
                              | number : "1.2-2"
                          }}
                        </td>
                        <td class="text-center">
                          {{
                            objectValues(qData.value)[3]["engaged_time"] || 0
                              | number : "1.2-2"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
