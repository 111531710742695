import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'apium-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  constructor(private _loader: LoaderService) {}

  public isVisible: boolean = false;
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.getLoaderState();
  }

  /**
   * *Getting loader state from the subject
   *
   * @date 28 June 2022
   * @developer Apium
   */
  private getLoaderState() {
    this.subscriptions.push(
      this._loader.loader$.subscribe({
        next: (state) => {
          switch (state) {
            case 'showloader':
              setTimeout(() => {
                this.isVisible = true;
              }, 0);
              break;
            case 'hideloader':
              setTimeout(() => {
                this.isVisible = false;
              }, 0);
              break;
          }
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
