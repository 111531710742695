import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { appSettings, routes } from '@app/configs';
import { AuthenticationService } from '@app/core/authentication';
import { ResponsiveMenuService } from '@app/core/services/responsive-menu.service';
import { LoginComponent } from '@app/pages/auth/_components';
import { ProfileUpdateService } from '@app/pages/profile/services/profile-update.service';
import { ApiumManagerSidebarLayoutComponent } from '../apium-sidebar-layout/apium-sidebar-layout.component';
import { TManagerDetails } from '@app/shared/types/common.types';
import { decryption } from '@app/shared/utilities';
import { LoaderService } from '@app/shared/modules/loader';
import { delay, filter, Observable } from 'rxjs';

@Component({
  providers: [ApiumManagerSidebarLayoutComponent, LoginComponent],
  selector: 'apium-header-layout',
  templateUrl: './apium-header-layout.component.html',
  styleUrls: ['./apium-header-layout.component.scss'],
})
export class ApiumManagerHeaderLayoutComponent implements OnInit {
  public routes: typeof routes = routes;
  public user: TManagerDetails;

  constructor(
    private _authService: AuthenticationService,
    private _proUpdateService: ProfileUpdateService,
    private router: Router,
    private rMenu: ResponsiveMenuService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    // Subscribe to the currentMessage observable and update user details after a 2-second delay if the message is true
    this._proUpdateService.currentMessage
      .pipe(
        filter((m) => !!m), // Only proceed if the message is true
        delay(2000) // Simulate a 2-second delay
      )
      .subscribe(() => {
        this.updateUserDetails();
      });

    // Immediately update user details if the message is false
    this._proUpdateService.currentMessage
      .pipe(
        filter((m) => !m) // Proceed only if the message is false
      )
      .subscribe(() => {
        this.updateUserDetails();
      });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
      }

      if (event instanceof NavigationEnd) {
        if (event.url) {
          this.closeCartMenu();
          this.closeAccountBar();
        }
      }
    });
  }
  /**
   * Helper method to update user details by decrypting stored user data from cookies.
   *
   * @private
   */
  private updateUserDetails(): void {
    this.user = decryption(
      this._authService.retrieveCookie(appSettings.userDetails)
    );
  }

  //Mobile Cart Toggle Menu
  openCartMenu() {
    let classSelect = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    );
    let numberclass = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    ).length;
    let i: any;
    for (i = 0; i < numberclass; i++) {
      classSelect[i].classList.add('top-toggle');
    }
  }

  closeCartMenu() {
    let classSelect = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    );
    let numberclass = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    ).length;
    let i: any;
    for (i = 0; i < numberclass; i++) {
      classSelect[i].classList.remove('top-toggle');
    }
  }

  /*
   * toggle cart menu
   */
  toggleCartMenu() {
    let classSelect = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    );
    let numberclass = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    ).length;
    let i: any;
    for (i = 0; i < numberclass; i++) {
      classSelect[i].classList.toggle('top-toggle');
    }
  }

  /*
   * open the Profile Menu
   */
  public openProfileMenu = (): void => {
    let classSelect = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    );
    let numberclass = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    ).length;
    let i: any;
    for (i = 0; i < numberclass; i++) {
      classSelect[i].classList.add('top-toggle');
    }
  };

  /*
   * close profile menu
   */
  public closeProfileMenu = (): void => {
    let classSelect = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    );
    let numberclass = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    ).length;
    let i: any;
    for (i = 0; i < numberclass; i++) {
      classSelect[i].classList.remove('top-toggle');
    }
    this.openAccountBar();
  };

  /*
   * toggle profile menu
   */
  public toggleProfileMenu = (): void => {
    let classSelect = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    );
    let numberclass = document.querySelectorAll(
      '.m-topbar.m-stack.m-stack--ver.m-stack--general'
    ).length;
    let i: any;
    for (i = 0; i < numberclass; i++) {
      classSelect[i].classList.toggle('top-toggle');
    }
  };

  /*
   * Method:mobileMenuOpen
   * Reason:To open repsonsive menu
   * Date:11th August,2022
   */
  mobileMenuOpen() {
    this.rMenu.changeMessage(true);
  }

  /*
   * Open Accounts Section
   */
  openAccountBar() {
    let classSelect = document.querySelectorAll(
      '.m-nav__item.m-topbar__user-profile.m-dropdown.m-dropdown--medium.m-dropdown--arrow.m-dropdown--align-right.m-dropdown--mobile-full-width.m-dropdown--skin-light'
    );

    let numberclass = document.querySelectorAll(
      '.m-nav__item.m-topbar__user-profile.m-dropdown.m-dropdown--medium.m-dropdown--arrow.m-dropdown--align-right.m-dropdown--mobile-full-width.m-dropdown--skin-light'
    ).length;
    let i: any;
    for (i = 0; i < numberclass; i++) {
      classSelect[i].classList.add('m-dropdown--open');
    }
  }

  /*
   * close account bar
   */
  closeAccountBar() {
    let classSelect = document.querySelectorAll(
      '.m-nav__item.m-topbar__user-profile.m-dropdown.m-dropdown--medium.m-dropdown--arrow.m-dropdown--align-right.m-dropdown--mobile-full-width.m-dropdown--skin-light'
    );

    let numberclass = document.querySelectorAll(
      '.m-nav__item.m-topbar__user-profile.m-dropdown.m-dropdown--medium.m-dropdown--arrow.m-dropdown--align-right.m-dropdown--mobile-full-width.m-dropdown--skin-light'
    ).length;
    let i: any;
    for (i = 0; i < numberclass; i++) {
      classSelect[i].classList.remove('m-dropdown--open');
    }
  }

  /*
   * on/off account bar
   */
  toggleAccountBar() {
    let classSelect = document.querySelectorAll(
      '.m-nav__item.m-topbar__user-profile.m-dropdown.m-dropdown--medium.m-dropdown--arrow.m-dropdown--align-right.m-dropdown--mobile-full-width.m-dropdown--skin-light'
    );

    let numberclass = document.querySelectorAll(
      '.m-nav__item.m-topbar__user-profile.m-dropdown.m-dropdown--medium.m-dropdown--arrow.m-dropdown--align-right.m-dropdown--mobile-full-width.m-dropdown--skin-light'
    ).length;
    let i: any;
    for (i = 0; i < numberclass; i++) {
      classSelect[i].classList.toggle('m-dropdown--open');
    }
  }

  /*
   * log out function
   */
  public logout = async (event: Event): Promise<void> => {
    this._authService.logout();
  };
}
