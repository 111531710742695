// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL: 'https://manager-api.dev.apiumdelivery.com/api/',


  PULL_COMMUNICATION_API_ENDPOINT:
    'http://manager-api.dev.apiumdelivery.com/pull-communications/',

  sentry_dsn:
    'https://bb910169d9af4c4d9828317aef4bfcd5@o18016.ingest.sentry.io/1333204',
  sentry_dsn_ajs: 'https://bb910169d9af4c4d9828317aef4bfcd5@sentry.io/1333204',
  firebase: {
    apiKey: 'AIzaSyC14OpYj_ydJQiOKCAE48DgYA5c-XfdnJQ',
    databaseURL:
      'https://apium-delivery-development-default-rtdb.firebaseio.com',
    authDomain: 'apium-delivery-development.firebaseapp.com',
    projectId: 'apium-delivery-development',
    storageBucket: 'apium-delivery-development.appspot.com',
    messagingSenderId: '183924484488',
    appId: '1:183924484488:web:34b1f6c9ab1e0cf7b3b23d',
    measurementId: 'G-NZ0FGJ8TV6',
  },

  GMAP_API_KEY: 'AIzaSyCaRgjkFQwePzWVMpcQ614kylCnmb_hFqo',
  states: [
    {
      id: 'CA',
      name: 'California',
      timezone: 'America/Los_Angeles',
    },
    {
      id: 'NV',
      name: 'Nevada',
      timezone: 'America/Los_Angeles',
    },
    {
      id: 'UT',
      name: 'Utah',
      timezone: 'America/Denver',
    },
    {
      id: 'ID',
      name: 'Idaho',
      timezone: 'America/Boise',
    },
    {
      id: 'WA',
      name: 'Washington',
      timezone: 'America/Los_Angeles',
    },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
