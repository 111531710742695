<form [formGroup]="form">
  <mat-form-field appearance="fill">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Start date" formControlName="sDate" />
      <input matEndDate placeholder="End date" formControlName="eDate" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <div class="text-danger" *ngIf="form.hasError('dateRangeExceeded')">
    Date range should not exceed 6 months.
  </div>
</form>
