<!-- BEGIN: Subheader -->
<div class="m-subheader">
  <div class="d-flex align-items-center pb-4">
    <div class="mr-auto">
      <h3>California Reports</h3>
      <p>{{ name }}</p>
    </div>
    <div></div>
  </div>
</div>

<div class="m-content">
  <form class="drive-reports-main" [formGroup]="caFilterForm">
    <div class="row ca_report search-panel search-panel-normal">
      <div class="col-md-4 col-sm-12">
        <div class="search-late-reports">
          <multi-select
            *ngIf="loadMkDrp"
            [parent]="mkOfficeForm"
            [placeholder]="mkOfficePlaceholder"
            [data]="MODrpdwn"
            [idField]="zoneIdField"
            [textField]="zoneTextField"
            (selectOneEvent)="selectZones($event)"
            (selectAllEvent)="onSelectAllZone($event)"
            (deSelectOneEvent)="deselectZone($event)"
            (deSelectAllEvent)="onDeSelectAllZone($event)"
          ></multi-select>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="search-late-reports">
          <multi-select
            [placeholder]="driverPlaceholder"
            [parent]="driverForm"
            [data]="driverList"
            [idField]="driverIdField"
            [textField]="driverTextField"
            (selectOneEvent)="selectDrivers($event)"
            (selectAllEvent)="onSelectAllDrivers($event)"
            (deSelectOneEvent)="deselectDriver($event)"
            (deSelectAllEvent)="onDeSelectAllDrivers($event)"
          ></multi-select>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="search-late-reports">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Start Date</mat-label>
            <input
              matInput
              [matDatepicker]="sdate"
              formControlName="st_dt"
              [min]="minDateSt"
              [max]="maxDateSt"
              (focus)="sdate.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="sdate"
            ></mat-datepicker-toggle>
            <mat-datepicker #sdate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="search-late-reports">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>End Date</mat-label>
            <input
              matInput
              [matDatepicker]="edate"
              formControlName="en_dt"
              [min]="minDateEn"
              [max]="maxDateEn"
              (focus)="edate.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="edate"
            ></mat-datepicker-toggle>
            <mat-datepicker #edate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <button
          type="button"
          class="btn blue-color mt-2"
          (click)="search()"
          [disabled]="
            !this.caFilterForm.valid ||
            !this.mkOfficeForm.valid ||
            !this.driverForm.valid
          "
        >
          Search
        </button>
      </div>
    </div>
  </form>
  <california-reports></california-reports>
</div>
