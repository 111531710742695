import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor() {}

  private _loaderSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    'hideloader'
  );
  public loader$ = this._loaderSubject.asObservable();

  /**
   * *Show loader
   *
   * @date 28 June 2022
   * @developer Apium
   */
  public showLoader() {
    this._loaderSubject.next('showloader');
  }

  /**
   * *Hides Loader
   *
   * @date 28 June 2022
   * @developer Apium
   */
  public hideLoader() {
    this._loaderSubject.next('hideloader');
  }
}
