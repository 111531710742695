<ul class="tree_check">
  <ng-container *ngFor="let node of treeJSON">
    <li>

      <label class="container_ch">
        <input
          *ngIf="level !== 0 && level !== 1"
          type="checkbox"
          [checked]="node.checked"
          (change)="onNodeChecked($event, node)"
        />
        <span class="checkmark_ch"></span>
      
        <span class="tree_text" (click)="toggleNode(node.id)"> {{ node.name }} </span>
      </label>
      <ul>
        <ng-container *ngFor="let child of node.children">
          <tree
            [treeJSON]="[child]"
            [level]="level + 1"
            (nodeChecked)="nodeChecked.emit($event)"
          ></tree>
        </ng-container>
      </ul>
    </li>
  </ng-container>
</ul>
