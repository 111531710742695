<form [formGroup]="parent">
  <ng-multiselect-dropdown
    class="multi_drop"
    formControlName="multiselect"
    [placeholder]="placeholder"
    [settings]="dropdownSettingsForZones"
    [data]="data"
    (onSelect)="selectOne($event)"
    (onSelectAll)="selectAll($event)"
    (onDeSelect)="deselectOne($event)"
    (onDeSelectAll)="onDeSelectAll($event)"
  >
  </ng-multiselect-dropdown>
</form>
