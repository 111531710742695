<div class="container">
   <form class="future_fit" [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- First Row -->
    <div class="row">
      <!-- Market Office Multi-Select -->
      <div class="col-md-4 pb-3">
        <multi-select-search
          [placeholder]="'Select Market Office'"
          [optionsData]="mkOfficeList"
          (selectedOptions)="onSelectedOptions($event, 'market_office')"
          [required]="true"
          [formSubmitted]="formSubmitted"
        ></multi-select-search>
      </div>

      <!-- Drivers Multi-Select -->
      <div class="col-md-4 pb-3">
        <multi-select-search
          [placeholder]="'Select Drivers'"
          [optionsData]="driversList"
          (selectedOptions)="onSelectedOptions($event, 'drivers')"
          [required]="true"
          [formSubmitted]="formSubmitted"
        ></multi-select-search>
      </div>

      <!-- Start Date Picker -->
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="custom-form-field"
          style="width: 100%"
        >
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="startDatePicker"
            formControlName="startDate"
            placeholder="Choose a start date"
            [min]="minDate"
            [max]="maxDate"
            class="text-ind"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <!-- Second Row -->
    <div class="row mt-3">
      <!-- End Date Picker -->
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="custom-form-field"
          style="width: 100%"
        >
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="endDatePicker"
            formControlName="endDate"
            placeholder="Choose an end date"
            [min]="minDate"
            [max]="maxDate"
            class="text-ind"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Empty Columns to Balance the Layout -->
      <div class="col-md-8"></div>
    </div>

    <!-- Third Row -->
    <div class="row mt-3">
      <!-- Submit Button -->
      <div class="col-md-12 text-left">
        <button class="blue-color btn" type="submit">Search</button>
      </div>
    </div>
  </form>
</div>

