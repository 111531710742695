import { Injectable } from '@angular/core';
import apiEndPoints from '@apiEndPoints';
import { HttpService } from '@app/core/http';
import { catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CaliforniaReportService {
  constructor(private _http: HttpService) {}

  /*
   * Method:getCAReportsAPI
   * Reason: getting the driver/california reports
   * Date:5th August, 2022
   */
  public getCAReportsAPI(params, reportVersion) {
    return this._http
      .post(`${apiEndPoints.ca_report_list}${reportVersion}`, params)
      .pipe(
        map((response: Response) => {
          return response;
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  /*
   * Method:getCAReportsAPI
   * Reason: getting the driver/california reports
   * Date:5th August, 2022
   */
  public getOLDCAReportsAPI(params, reportVersion) {
    return this._http
      .post(`${apiEndPoints.old_ca_report_list}${reportVersion}`, params)
      .pipe(
        map((response: Response) => {
          return response;
        }),
        catchError((error) => {
          throw error;
        })
      );
  }
}
