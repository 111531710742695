<div class="m-login__signin">
  <div class="m-login__head">
    <h3 class="m-login__title">Sign In To Manager Portal</h3>
  </div>
  <form class="m-login__form m-form" [formGroup]="loginForm" novalidate>
    <div class="form-group m-form__group">
      <input
        type="text"
        class="form-control m-input"
        formControlName="email"
        [placeholder]="'Email'"
      />

      <span
        class="text-danger"
        *ngIf="
          (formControl['email'].touched || submitted) &&
          formControl['email'].errors?.['required']
        "
      >
        {{ errors.EMAIL }}
      </span>
    </div>

    <div class="form-group m-form__group">
      <div class="input-group">
        <input
          type="{{ passwordVisible ? 'text' : 'password' }}"
          class="form-control m-input m-login__form-input--last"
          formControlName="password"
          [placeholder]="'Password'"
        />
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-link password-toggle"
            (click)="togglePasswordVisibility()"
          >
            <mat-icon *ngIf="!passwordVisible">visibility</mat-icon>
            <!-- Show icon -->
            <mat-icon *ngIf="passwordVisible">visibility_off</mat-icon>
            <!-- Hide icon -->
          </button>
        </div>
      </div>
      <span
        class="text-danger"
        *ngIf="(formControl['password'].touched || submitted) && formControl['password'].errors?.['required']"
      >
        {{ errors.PASSWORD }}
      </span>
    </div>

    <div class="row m-login__form-sub">
      <div class="col m--align-left m-login__form-left">
        <label class="m-checkbox m-checkbox--focus">
          <input type="checkbox" formControlName="remember" />
          Remember me
          <span></span>
        </label>
      </div>
      <div class="col m--align-right m-login__form-right">
        <a
          href="javascript:;"
          id="m_login_forget_password"
          class="m-link"
          routerLink="/{{ routes.FORGOT_PASSWORD }}"
        >
          Forgot Password ?
        </a>
      </div>
    </div>

    <div class="m-login__form-action">
      <button
        class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
        type="submit"
        (click)="onLogin()"
      >
        <i class="fa fa-cog fa-spin" *ngIf="isLoading"></i>
        <span>Sign In</span>
      </button>
    </div>
  </form>
</div>
